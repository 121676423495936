// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBJ-utMGgeTD0Qz6VaAO31uQaBU9INy6Yo",
  authDomain: "foam-laundry.firebaseapp.com",
  projectId: "foam-laundry",
  storageBucket: "foam-laundry.appspot.com",
  messagingSenderId: "509361008730",
  appId: "1:509361008730:web:dc831f3276c77415232863",
  measurementId: "G-G97DJSPJZK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth();