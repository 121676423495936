import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  oneTime: false,
};

const changeOneTimeAction = (state, action) => {
  state.oneTime = action.payload;
};
const resetOneTimeAction = (state, action) => {
  return initialState;
};

export const oneTimeSlice = createSlice({
  name: "address",
  initialState: { ...initialState },
  reducers: {
    changeOneTime: changeOneTimeAction,
    resetOneTime: resetOneTimeAction,
  },
});

export const { changeOneTime, resetOneTime } = oneTimeSlice.actions;
export default oneTimeSlice.reducer;
