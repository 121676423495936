import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  intentId: null,
  clientSecret: null,
  paymentMethodId: null,
  cardNo: null,
  savedCardUse: false,
};

const updateIntentIdAction = (state, action) => {
  state.intentId = action.payload;
};
const updateClientSecretAction = (state, action) => {
  state.clientSecret = action.payload;
};
const paymentMethodAction = (state, action) => {
  state.paymentMethodId = action.payload;
};
const cardNoAction = (state, action) => {
  state.cardNo = action.payload;
};
const updateSavedCardUseAction = (state, action) => {
  state.savedCardUse = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};

const stripeOrderSlice = createSlice({
  name: "stripeOrder",
  initialState: { ...initialState },
  reducers: {
    updateIntentId: updateIntentIdAction,
    updateClientSecret: updateClientSecretAction,
    updatePaymentMethod: paymentMethodAction,
    updateCardNo: cardNoAction,
    updateSavedCardUse: updateSavedCardUseAction,
    resetStateStripeOrder: resetStateactions,
  },
});

export const {
  updateIntentId,
  updateClientSecret,
  updatePaymentMethod,
  updateCardNo,
  updateSavedCardUse,
  resetStateStripeOrder,
} = stripeOrderSlice.actions;
export default stripeOrderSlice.reducer;
