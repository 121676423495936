import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  firstNameErr: {
    err: false,
    message: "",
  },
  lastNameErr: {
    err: false,
    message: "",
  },
  emailErr: {
    err: false,
    message: "",
  },
  phoneNoErr: {
    err: false,
    message: "",
  },
  passwordErr: {
    err: false,
    message: "",
  },
  cPasswordErr: {
    err: false,
    message: "",
  },
};

const validateAccountSlice = createSlice({
  name: "AccountValidate",
  initialState,
  reducers: {
    firstNameErrChange: (state, action) => {
      state.firstNameErr = action.payload;
    },
    lastNameErrChange: (state, action) => {
      state.lastNameErr = action.payload;
    },
    emailErrChange: (state, action) => {
      state.emailErr = action.payload;
    },
    phoneNoErrChange: (state, action) => {
      state.phoneNoErr = action.payload;
    },
    passwordErrChange: (state, action) => {
      state.passwordErr = action.payload;
    },
    cPasswordErrChange: (state, action) => {
      state.cPasswordErr = action.payload;
    },
    resetAllValidations: (state, action) => {
      return initialState;
    },
  },
});

export const {
  firstNameErrChange,
  lastNameErrChange,
  emailErrChange,
  phoneNoErrChange,
  passwordErrChange,
  cPasswordErrChange,
  resetAllValidations,
} = validateAccountSlice.actions;
export default validateAccountSlice.reducer;
