import axios from "axios";

export const createRepeatPickup = async (userData) => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/repeatPickup",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        ...userData,
        pickupStart: "9am",
        pickupEnd: "6pm",
        duration: 1,
        deleteOld: 1,
        ordersCreatedSoFar: 0,
        schedulerType: 1,
      }
    );

    return response.data;
  } catch (e) {
    return e;
  }
};
