import { ThemeProvider } from "@mui/material";
import { theme } from "./styles/MUItheme";
import { RouterProvider } from "react-router-dom";
import AppRoutes from "./routes/MainRoute";
import "./styles/app.scss";
import store from "./store/redux";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-N322D2K",
  events: {
     purchase: 'Purchase',
     "sign_up": 'Sign up'
  }
};

function App() {
  TagManager.initialize(tagManagerArgs);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <RouterProvider router={AppRoutes} />
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
