import { addDoc, collection } from "firebase/firestore";
import { firestore } from "./firebase";

export const addCustomer = async (email, stripeCustomer) => {
  const collectionRef = collection(firestore, "foam-customers");
  const customer = await addDoc(collectionRef, {
    email,
    stripeCustomer,
  });

  return customer.id;
};
