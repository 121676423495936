import { Grid, Typography } from "@mui/material";
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { addCreditCard } from "../../../cleancloud/addCreditCard";
import CustomButton from "../../shared/Button";
import CustomizedSnackbars from "../../shared/Snackbar";
import { getCustomerData } from "../../../firebase/getCustomerData";
import axios from "axios";

export default function CardForm({ reload, setReload }) {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    message: "",
    open: false,
    severity: "error",
  });

  const createPaymentIntent = async () => {
    setLoading(true);
    const response = await stripe.confirmSetup({
      elements,
      redirect: "if_required",
    });

    if (response.error) {
      setSnack({
        open: true,
        message: "There was an error trying saving card",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    // Update user with default card. Required for CleanCloud to charge customers later in the process
    const data = await getCustomerData();
    axios
      .post(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/updateCustomersPayment`,
        {
          customer: data.stripeCustomer,
          paymentID: response.setupIntent.payment_method,
        }
      )
      .catch(function (error) {
        console.log(error);

        setSnack({
          open: true,
          message: "There was an error trying to update your payment method.",
        });
        return;
      });

    // Confirm the new card in CleanCloud
    const addCredit = await addCreditCard(response.setupIntent.payment_method);
    if (addCredit.Error) {
      console.log(addCredit.Error);
      setSnack({
        open: true,
        message: "There was an error trying updating the card",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    setSnack({
      open: true,
      message: "Card information saved",
      severity: "success",
    });
    setReload(!reload);
  };

  return (
    <>
      <CustomizedSnackbars
        open={snack.open}
        message={snack.message}
        severity={snack.severity}
        handleClose={() => setSnack({ ...snack, open: false, message: "" })}
      />
      <Grid item xs={12}>
        <PaymentElement />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Billing address</Typography>
      </Grid>
      <Grid item xs={12}>
        <AddressElement
          options={{
            mode: "billing",
            autocomplete: {
              mode: "google_maps_api",
              apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
            },
          }}
        />
      </Grid>
      <Grid item>
        <CustomButton
          variant="contained"
          onClick={() => createPaymentIntent()}
          loader={loading ? 1 : 0}
          disabled={loading}
        >
          Save Payment Method
        </CustomButton>
      </Grid>
    </>
  );
}
