import { Container } from "@mui/system";
import { Grid, List, ListItem, ListItemText } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import MarkerIcon from "@mui/icons-material/RoomOutlined";
import PaymentIcon from "@mui/icons-material/PaymentsOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import TodayIcon from "@mui/icons-material/TodayOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToAppOutlined";
import NavbarUser from "../components/shared/NavbarUser";
import { useCallback, useEffect, useState } from "react";
import { auth } from '../firebase/firebase';
import { signOut } from "firebase/auth";
import {
  Link,
  Outlet,
  ScrollRestoration,
  useLocation,
  // useNavigate,
} from "react-router-dom";
import {
  resetCart,
  resetStateStoreProducts,
} from "../store/products/storeProducts";
import { resetStateAddress } from "../store/address/storeAddress";
import { resetStateBottomBar } from "../store/bottom-bar/storeBottomBar";
import { resetStateCreateAccount } from "../store/create-account/storeCreateAccount";
import { resetStateDate } from "../store/date/storeDate";
import { resetStateSteps } from "../store/steps/storeSteps";
import { resetStateStoreOrder } from "../store/order/storeOrder";
import { resetStateStripeOrder } from "../store/create-order/storeStripeOrder";
import { resetStateStripePaymentCard } from "../store/stripeCreatePayment/storeStripPaymentCreate";
import { resetStateSubscription } from "../store/subscription/storeSubscription";
import { resetStateUpdateOrder } from "../store/updateOrder/storeUpdateOrder";
import { useDispatch } from "react-redux";
import { resetAllValidations } from "../store/createAccountValidate/storeValidateAccount";

export default function UserAccount() {
  const { pathname } = useLocation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState(null);

  const checkURL = useCallback(() => {
    if (pathname === "/account") setActiveItem("my_account");
    else if (pathname === "/account/address") setActiveItem("my_address");
    else if (pathname === "/account/payment") setActiveItem("payment_method");
    else if (pathname === "/account/orders") setActiveItem("my_orders");
    else if (pathname === "/account/subscriptions")
      setActiveItem("my_subscriptions");
    else if (pathname === "/account/refer") setActiveItem("refer_a_friend");
  }, [pathname]);

  const logout = () => {
    signOut(auth);
    localStorage.clear();
    dispatch(resetCart());
    dispatch(resetStateAddress());
    dispatch(resetStateBottomBar());
    dispatch(resetStateCreateAccount());
    dispatch(resetStateDate());
    dispatch(resetStateSteps());
    dispatch(resetStateStoreOrder());
    dispatch(resetStateStoreProducts());
    dispatch(resetStateStripeOrder());
    dispatch(resetStateStripePaymentCard());
    dispatch(resetStateSubscription());
    dispatch(resetStateUpdateOrder());
    dispatch(resetAllValidations());
    window.location.href = "https://foamlaundry.com.au";
  };

  useEffect(() => {
    checkURL();
  }, [checkURL]);
  return (
    <>
      <ScrollRestoration />
      <NavbarUser className="d-none" />
      <div className="d-flex"></div>
      <Container maxWidth="lg" className="d-flex mt-3 mt-md-5 pt-2">
        <Grid container>
          <Grid item md={3} className={"d-none d-md-block "}>
            <List sx={{ width: 230 }}>
              <Link
                to={"/account/orders"}
                className="text-decoration-none text-dark"
              >
                <ListItem
                  className="p-2 px-3 mb-2 d-flex justify-content-start"
                  sx={
                    activeItem === "my_orders"
                      ? { background: "white", borderRadius: 10 }
                      : null
                  }
                >
                  <FormatListBulletedIcon />
                  <span className="mx-1"></span>
                  <ListItemText
                    primary="My Orders"
                    className="ml-2 text-capitalize"
                  />
                </ListItem>
              </Link>

              <Link
                to={"/account/address"}
                className="text-decoration-none text-dark"
              >
                <ListItem
                  className="p-2 px-3 mb-2 d-flex justify-content-start"
                  sx={
                    activeItem === "my_address"
                      ? { background: "white", borderRadius: 10 }
                      : null
                  }
                >
                  <MarkerIcon />
                  <span className="mx-1"></span>
                  <ListItemText
                    primary="My Address"
                    className="ml-2 text-capitalize"
                  />
                </ListItem>
              </Link>
              <Link
                to={"/account/payment"}
                className="text-decoration-none text-dark"
              >
                <ListItem
                  className="p-2 px-3 mb-2 d-flex justify-content-start"
                  sx={
                    activeItem === "payment_method"
                      ? { background: "white", borderRadius: 10 }
                      : null
                  }
                >
                  <PaymentIcon />
                  <span className="mx-1"></span>
                  <ListItemText
                    primary="Payment Method"
                    className="ml-2 text-capitalize"
                  />
                </ListItem>
              </Link>

              <Link
                to={"/account/subscriptions"}
                className="text-decoration-none text-dark"
              >
                <ListItem
                  className="p-2 px-3 mb-2 d-flex justify-content-start"
                  sx={
                    activeItem === "my_subscriptions"
                      ? { background: "white", borderRadius: 10 }
                      : null
                  }
                >
                  <TodayIcon />
                  <span className="mx-1"></span>
                  <ListItemText
                    primary="My Subscriptions"
                    className="ml-2 text-capitalize"
                  />
                </ListItem>
              </Link>
              <Link to={"/account"} className="text-decoration-none text-dark">
                <ListItem
                  className="p-2 px-3 mb-2 d-flex justify-content-start"
                  sx={
                    activeItem === "my_account"
                      ? { background: "white", borderRadius: 10 }
                      : null
                  }
                >
                  <AccountCircleIcon />
                  <span className="mx-1"></span>
                  <ListItemText
                    primary="My Account"
                    className="ml-2 text-capitalize"
                  />
                </ListItem>
              </Link>
              <ListItem
                className="p-2 px-3 mb-2 d-flex justify-content-start cursor-pointer"
                onClick={logout}
              >
                <ExitToAppIcon />
                <span className="mx-1"></span>
                <ListItemText
                  primary="Log out"
                  className="ml-2 text-capitalize"
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={9} className="p-2 p-md-3">
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
