import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  Stack,
  Container,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { useState } from "react";
import { getCustomerData } from "../../../firebase/getCustomerData";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCardNo,
  updateClientSecret,
  updateIntentId,
  updateSavedCardUse,
} from "../../../store/create-order/storeStripeOrder";
import FormCustom from "./Form";
import { Spinner } from "react-bootstrap";
import { updatePaymentMethod } from "../../../store/create-order/storeStripeOrder";
import { ScrollRestoration } from "react-router-dom";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function Payment() {
  const [billingDiffrent, setBillingDiffrent] = useState(false);
  const [, setLoading] = useState(true);
  const { clientSecret } = useSelector((store) => store.stripeOrder);
  const { paymentMethodId, savedCardUse } = useSelector(
    (store) => store.stripeOrder
  );
  const [loading, setLoadingCards] = useState(true);
  const [cardsData, setCardsData] = useState([]);
  const dispatch = useDispatch();

  const getInformation = async () => {
    setLoadingCards(true);
    const data = await getCustomerData();
    axios
      .post(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/customerPaymentMethods`,
        {
          customer: data.stripeCustomer,
        }
      )
      .then(function (response) {
        setCardsData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    setLoadingCards(false);
  };

  const paymentMethod = async () => {
    setLoading(true);
    const data = await getCustomerData();
    localStorage.setItem("stripeCustomer", data.stripeCustomer);
    axios
      .post(`${process.env.REACT_APP_FIREBASE_FUNCTIONS}/setupIntent`, {
        customer: data.stripeCustomer,
      })
      .then(function (response) {
        dispatch(updateClientSecret(response.data.client_secret));
        dispatch(updateIntentId(response.data.id));
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getInformation();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    paymentMethod();
    //eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="sm">
      <ScrollRestoration />
      <Grid container spacing={3} justifyContent={"left"} className="mb-5">
        <Grid item xs={12}>
          <Typography variant="h1" align="center" className="mb-4">
            Payment Method
          </Typography>
          <Typography variant="body1" className="text-center m-0">
            Your card will be charged after delivery.
          </Typography>
        </Grid>
        {localStorage.getItem("cid") ? (
          !loading ? (
            cardsData.length ? (
              cardsData.map((item, i) => (
                <Grid key={"card-" + i} item xs={12}>
                  <List
                    subheader={
                      <Typography
                        variant="h3"
                        component="div"
                        className="py-3 px-4"
                        style={{
                          fontSize: 18,
                          borderBottom: "solid #E8F3F3 1px",
                        }}
                      >
                        Your previous payment method
                      </Typography>
                    }
                    sx={{ borderRadius: 5 }}
                    className="bg-white py-1"
                  >
                    <div>
                      <ListItem
                        className="px-4"
                        sx={{
                          borderTop: i === 0 ? "none" : "1px solid #bbb",
                        }}
                        secondaryAction={
                          <Button
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              if (paymentMethodId === item.id) {
                                dispatch(updatePaymentMethod(null));
                                dispatch(updateCardNo(""));
                                dispatch(updateSavedCardUse(false));
                              } else {
                                dispatch(updatePaymentMethod(item.id));
                                dispatch(updateCardNo(item.card.last4));
                                dispatch(updateSavedCardUse(true));
                              }
                            }}
                          >
                            <Typography
                              className="text-capitalize"
                              sx={{
                                color:
                                  paymentMethodId === item.id
                                    ? "green"
                                    : "#405C6E",
                              }}
                            >
                              {paymentMethodId === item.id
                                ? "selected"
                                : "select"}
                            </Typography>
                          </Button>
                        }
                      >
                        <Stack direction={"row"}>
                          <CreditCardOutlinedIcon />
                          <div className="mx-2">
                            <Typography
                              variant="body1"
                              className="text-uppercase"
                            >
                              {item.card.brand}
                            </Typography>
                            <Typography variant="body2" className="m-0 p-0">
                              **** **** **** {item.card.last4}
                            </Typography>
                          </div>
                        </Stack>
                      </ListItem>
                    </div>
                  </List>
                </Grid>
              ))
            ) : (
              ""
            )
          ) : (
            <div className="d-flex justify-content-center mt-4">
              <Spinner size="sm" animation="grow" />
            </div>
          )
        ) : (
          ""
        )}
        {!savedCardUse ? (
          paymentMethodId ? (
            <Grid item xs={12}>
              <Box
                sx={{ borderRadius: 5 }}
                className="p-3 py-4 text-white mt-3 w-100 bg-white d-flex align-items-center "
              >
                <CreditCardOutlinedIcon className="mx-1" fontSize="medium" />
                <u
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(updatePaymentMethod(null));
                    dispatch(updateCardNo(""));
                  }}
                >
                  Do you want to select a new payment method?
                </u>
              </Box>
            </Grid>
          ) : clientSecret ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" className="mb-3">
                  New card
                </Typography>

                <Elements
                  stripe={stripePromise}
                  options={{
                    clientSecret,

                    appearance: {
                      theme: "stripe",
                      labels: "floating",
                      rules: {
                        ".Input": {
                          border: "1px solid #999999",
                        },
                        ".Input:focus": {
                          boxShadow: "rgba(64, 92, 110,0.2) 0 0 0 2px",
                        },
                      },
                      variables: {
                        colorPrimary: "#405c6e",
                        colorSuccess: "#39b555",

                        colorBackground: "#ffffff",
                        colorText: "#30313d",
                        colorDanger: "#E63D36",
                        fontFamily: "Ideal Sans, system-ui, sans-serif",
                        spacingUnit: "3px",
                        spacingGridRow: "25px",
                      },
                    },
                  }}
                >
                  <FormCustom
                    clientSecret={clientSecret}
                    billingDiffrent={billingDiffrent}
                    cardsData={cardsData}
                  />
                </Elements>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={billingDiffrent}
                      onClick={(e) => setBillingDiffrent(e.target.checked)}
                    />
                  }
                  label="My billing address is different from my home address"
                />
              </Grid>
            </>
          ) : (
            <div className="d-flex justify-content-center w-100 mt-5">
              <Spinner animation="grow" size="sm" />
            </div>
          )
        ) : (
          ""
        )}
      </Grid>
    </Container>
  );
}
