import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateOrderID: null,
};

const updateOrderIDAction = (state, action) => {
  state.updateOrderID = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};

const updateOrderSlice = createSlice({
  name: "UpdateOrder",
  initialState,
  reducers: {
    changeOrderID: updateOrderIDAction,
    resetStateUpdateOrder: resetStateactions,
  },
});

export const { changeOrderID, resetStateUpdateOrder } =
  updateOrderSlice.actions;
export default updateOrderSlice.reducer;
