import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  step: 0,
};

const changeStepAction = (state, action) => {
  if (action.payload.toLowerCase() === "next") state.step = state.step + 1;
  if (action.payload.toLowerCase() === "back") state.step = state.step - 1;
};
const specificStepAction = (state, action) => {
  state.step = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};
const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    changeStep: changeStepAction,
    specificStep: specificStepAction,
    resetStateSteps: resetStateactions,
  },
});

export const { changeStep, specificStep, resetStateSteps } = stepSlice.actions;

export default stepSlice.reducer;
