import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeAddress } from "../../store/address/storeAddress";

export default function StoreAddress({ newAddress }) {
  const dispatch = useDispatch();
  const [first, setFirst] = useState(0);

  useEffect(() => {
    if (first) {
      dispatch(changeAddress(newAddress));
    } else {
      setFirst(first + 1);
    }
    // eslint-disable-next-line
  }, [newAddress, dispatch]);

  return <></>;
}
