import { CheckOutlined } from "@mui/icons-material";
import { Grid, Typography, Divider, List, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { deleteSubscription } from "../../../cleancloud/addRemoveSubscription";
import { checkSubscription } from "../../../cleancloud/checkSubscription";
import CustomButton from "../../shared/Button";
import CustomizedSnackbars from "../../shared/Snackbar";
import { auth } from '../../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function MySubscriptions() {
  const [user] = useAuthState(auth);
  const { showScheduleButton } = useSelector((store) => store.scheduleButton);
  const [activeSubscription, setActiveSubscription] = useState(true);
  const [snack, setSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(false);
  const changeSubscription = (val) => {
    setActiveSubscription(val);
  };
  const navigate = useNavigate();

  const updateSubscription = async () => {
    setAction(true);
    if (activeSubscription.length) {
      const res = await deleteSubscription(activeSubscription[0].id);
      setAction(false);
      if (res.Error) return;
      setActiveSubscription([]);
    } else {
      navigate("/order");
    }
  };

  const getSubscription = async () => {
    setLoading(true);
    const res = await checkSubscription();

    changeSubscription(res);
    setLoading(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("cid")) {
      navigate("/login");
    }
    if(!user) {
      localStorage.removeItem("cid");
      navigate("/login");
    }
    getSubscription();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ScrollRestoration />
      <CustomizedSnackbars
        severity={"error"}
        message="Something went wrong"
        open={snack}
        handleClose={() => setSnack(false)}
      />
      {!loading ? (
        <Grid container spacing={3} justifyContent="center" className="mb-5">
          <Grid item xs={12}>
            <Typography variant="h4">My Subscriptions</Typography>
          </Grid>
          {activeSubscription.length ? (
            <Grid item xs={12}>
              <Box
                className="p-4 bg-white subscription-bg cursor-pointer"
                sx={{
                  borderRadius: 5,
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={5}
                    md={4}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div>
                    <Typography variant="caption" className="text-uppercase">
                      Subscription
                    </Typography>
                    <Typography variant="h5">FOAM Repeat</Typography>
                    </div>
                    <Divider
                      orientation="vertical"
                      className="m-0"
                      sx={{ border: "1px solid #bbb" }}
                    ></Divider>
                  </Grid>
                  <Grid item xs={7} md={8} className="px-md-5">
                    <Typography>From</Typography>
                    <Typography>$5 per Kilo + Free Delivery</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography>No active subscriptions.</Typography>
              <Divider sx={{marginTop: '24px', marginBottom: '24px', borderColor: "#405c6e"}} />
              <Typography variant="h6">Why subscribe to Foam?</Typography>
              <List className="p-0">
                <ListItem className="d-flex align-items-center justify-content-start px-0 ">
                  <CheckOutlined />
                  <Typography className="mx-2">Mates rates plus free delivery.</Typography>
                </ListItem>
                <ListItem className="d-flex align-items-center justify-content-start px-0">
                  <CheckOutlined />
                  <Typography className="mx-2">50% off our next day delivery option.</Typography>
                </ListItem>
                <ListItem className="d-flex align-items-center justify-content-start px-0">
                  <CheckOutlined />
                  <Typography className="mx-2">FOAM welcome kit.</Typography>
                </ListItem>
              </List>
            </Grid>
          )}

          <Grid item xs={12}>
            {activeSubscription.length ? (
              <CustomButton
                variant="contained"
                fullWidth
                onClick={() => updateSubscription()}
                loader={action ? 1 : 0}
                disabled={action}
              >
                Cancel my subscription
              </CustomButton>
            ) : (
              <CustomButton
                variant="contained"
                fullWidth
                onClick={() => updateSubscription()}
                loader={action ? 1 : 0}
                disabled={!showScheduleButton}
              >
                Schedule a Foam Repeat Subscription
              </CustomButton>
            )}
          </Grid>
        </Grid>
      ) : (
        <div className="d-flex align items-center justify-content-center">
          <Spinner animation="grow" />
        </div>
      )}
    </>
  );
}
