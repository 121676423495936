import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "./firebase";

export const updateCustomer = async (data) => {
  const docRef = doc(
    firestore,
    "foam-customers",
    JSON.parse(localStorage.getItem("firebaseID"))
  );
  const updatedIntent = await updateDoc(docRef, data);
  return updatedIntent;
};
