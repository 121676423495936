import axios from "axios";

export const checkSubscription = async () => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/getPickups",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        customerID: JSON.parse(localStorage.getItem("cid")),
      }
    );
    return response.data.Pickups;
  } catch (e) {
    return e;
  }
};
