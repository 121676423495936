import { Grid, Typography, List, ListItem, Button, Stack } from "@mui/material";
import { RoomOutlined } from "@mui/icons-material";
import CustomButton from "../../shared/Button";
import { LocationSearchInput } from "../../shared/GooglePlaces";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateCustomerInformation } from "../../../cleancloud/updateCustomerInformation";
import { changeAddress } from "../../../store/address/storeAddress";
import CustomizedSnackbars from "../../shared/Snackbar";
import { getCustomerInformation } from "../../../cleancloud/getCustomerInformation";
import { Spinner } from "react-bootstrap";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { auth } from '../../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function MyAddress() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { address } = useSelector((store) => store.address);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDelteLoading] = useState(false);
  const [gotAddress, setGotAddress] = useState(null);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    severity: "Error",
  });

  const getAddresses = async () => {
    const res = await getCustomerInformation();
    if (res.Error) {
      setSnackbar({
        message: "Error getting information!",
        severity: "error",
        open: true,
      });
    }

    setGotAddress({
      formatted_address: res.Address,
      latlng: [res.Lat, res.Lng],
    });
  };

  const updateAddress = async (del) => {
    del ? setDelteLoading(true) : setLoading(true);
    const res = await updateCustomerInformation(
      del
        ? { customerAddress: "", customerLat: "", customerLng: "" }
        : {
            customerAddress: address.formatted_address
              ?.split("//")
              .map((item) => item.trim())
              .join(" ")
              .trim(),
            customerLat: address.latlng[0],
            customerLng: address.latlng[1],
          }
    );
    if (!res.Error) getAddresses();

    setLoading(false);
    setDelteLoading(false);
    res.Error
      ? setSnackbar({
          message: "Something went wrong please try again!",
          severity: "error",
          open: true,
        })
      : setSnackbar({
          message: del
            ? "Address removed successfully"
            : "Address updated successfully",
          severity: del ? "error" : "success",
          open: true,
        });
  };

  useEffect(() => {
    if (!localStorage.getItem("cid")) {
      navigate("/login");
    }
    if(!user) {
      localStorage.removeItem("cid");
      navigate("/login");
    }
    dispatch(changeAddress(""));
    getAddresses();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <ScrollRestoration />
      <CustomizedSnackbars
        message={snackbar.message}
        open={snackbar.open}
        severity={snackbar.severity}
        handleClose={() => {
          setSnackbar({ ...snackbar, message: "", open: false });
        }}
      />
      <Grid container spacing={3} justifyContent="center" className="mb-5">
        <Grid item xs={12}>
          <Typography variant="h4">My Address</Typography>
        </Grid>
        <Grid item xs={12}>
          {gotAddress ? (
            gotAddress.formatted_address !== "" ? (
              <List
                dense={true}
                sx={{ borderRadius: 5 }}
                className="bg-white py-3 "
              >
                <div>
                  <ListItem
                    className="px-4"
                    secondaryAction={
                      <Button
                        edge="end"
                        aria-label="delete"
                        onClick={() => updateAddress(true)}
                        disabled={deleteLoading}
                      >
                        <Typography
                          color={deleteLoading ? "dark" : "error"}
                          className="text-capitalize"
                        >
                          delete
                        </Typography>
                      </Button>
                    }
                  >
                    <Stack direction={"row"}>
                      <RoomOutlined />
                      <div className="mx-2">
                        <Typography
                          variant="body1"
                          style={{ maxWidth: "300px" }}
                        >
                          {gotAddress.formatted_address
                            .split("//")
                            .map((item) => item.trim())
                            .join(" ")}
                        </Typography>
                      </div>
                    </Stack>
                  </ListItem>
                </div>
              </List>
            ) : null
          ) : (
            ""
          )}
          {!gotAddress ? <Spinner className="mx-auto" /> : ""}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Add new address</Typography>
        </Grid>
        <Grid item xs={12}>
          <LocationSearchInput address={""} />
        </Grid>
        <Grid item>
          <CustomButton
            disabled={address === "" || loading}
            loader={loading ? 1 : 0}
            variant="contained"
            onClick={() => updateAddress()}
          >
            Save new address
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
}
