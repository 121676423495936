import axios from "axios";

export const deleteOrder = async (orderID) => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/deleteOrder",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        orderID,
        sendEmail: 1,
      }
    );
    return response.data;
  } catch (e) {
    return e;
  }
};
