import { Grid, Typography, Box, Divider } from "@mui/material";
import { CheckOutlined } from "@mui/icons-material";
import { Basket, FoamBag } from "../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { changeSubscription } from "../../../store/subscription/storeSubscription";
import { useEffect } from "react";
import { resetCart } from "../../../store/products/storeProducts";
import { useState } from "react";
import { ScrollRestoration } from "react-router-dom";

export default function FoamPlan() {
  const dispatch = useDispatch();
  const { subscription } = useSelector((state) => state.subscription);
  const [firstMount, setFirstMount] = useState(0);

  const resetCartItems = () => {
    dispatch(resetCart());
  };
  // eslint-disable-next-line
  const changeFirstMount = () => {
    setFirstMount(firstMount + 1);
  };

  // eslint-disable-next-line
  useEffect(() => {
    if (firstMount > 0) resetCartItems();
    else changeFirstMount();
    // eslint-disable-next-line
  }, [subscription]);

  return (
    <Grid container justifyContent={"center"} alignItems="stretch" spacing={3}>
      <ScrollRestoration />
      <Grid item xs={12} md={6}>
        <Typography variant="h1" align="center" className="mb-4">
          Take your pick
        </Typography>
        <Typography
          variant="body1"
          className="text-center mx-auto"
          align="center"
        >
          Two flexible ways to get started; is this a once-off laundry SOS? Or are you interested in outsourcing your laundry entirely?
        </Typography>
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} sm={6} md={4}>
        <Box
          onClick={() => {
            dispatch(changeSubscription(false));
          }}
          className="p-4 bg-white subscription-bg-2 cursor-pointer h-100"
          sx={{
            borderRadius: "56px",
            position: "relative",
            border: !subscription ? "3px solid #375D70" : "3px solid white",
          }}
        >
          <Box
            className="bg-secondary"
            sx={{
              border: "2px solid #375D70",
              display: !subscription ? "inline-block" : "none",
              borderRadius: "50%",
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <CheckOutlined />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="d-flex align-items-center justify-content-start" style={{minHeight: "70px"}}>
                <Basket />
                <div className="mx-2">
                  <Typography variant="caption" className="text-uppercase">
                    One off
                  </Typography>
                  <Typography variant="h5">Wash, Dry & Fold</Typography>
                </div>
              </div>
              <Divider
                className="m-0 mt-3"
                sx={{ border: "1px solid #bbb" }}
              ></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="div">From</Typography>
              <Typography variant="h3" component="div" style={{fontSize:18}}>$6 per Kilo + $10 Delivery</Typography>
              <Typography variant="body1" component="div" className="my-2">
                Let us do the heavy lifting so you can have more time to do the things you love the most.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Box
          onClick={() => {
            dispatch(changeSubscription(true));
          }}
          className="p-4 bg-white subscription-bg-2 cursor-pointer"
          sx={{
            borderRadius: "56px",
            position: "relative",
            border: subscription ? "3px solid #375D70" : "3px solid white",
          }}
        >
          <Box
            className="bg-secondary"
            sx={{
              border: "2px solid #375D70",
              display: subscription ? "inline-block" : "none",
              borderRadius: "50%",
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <CheckOutlined />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="d-flex align-items-center justify-content-start" style={{minHeight: "70px"}}>
                <FoamBag />
                <div className="mx-2">
                  <Typography variant="caption" className="text-uppercase">
                    Subscription
                  </Typography>
                  <Typography variant="h5">FOAM Repeat</Typography>
                </div>
              </div>
              <Divider
                className="m-0 mt-3"
                sx={{ border: "1px solid #bbb" }}
              ></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="div">From</Typography>
              <Typography variant="h3" component="div" style={{fontSize:18}}>$5 per Kilo + Free Delivery</Typography>
              <Typography variant="body1" component="div" className="my-2">
                Laundry hack: Set up a recurring pick up to fall on the same day as 'bin day' so you don't forget to leave out your laundry!
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
