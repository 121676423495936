import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription: false,
};

const changeSubscriptionAction = (state, action) => {
  state.subscription = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    changeSubscription: changeSubscriptionAction,
    resetStateSubscription: resetStateactions,
  },
});

export const { changeSubscription, resetStateSubscription } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
