import { Container, Grid, Typography } from "@mui/material";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import CustomButton from "../components/shared/Button";
import TextFeildCustom from "../components/shared/TextFeild";
import Navbar from "../components/shared/Navbar";
import { useState } from "react";
import { resetPassword } from "../cleancloud/resetPassword";
import CustomizedSnackbars from "../components/shared/Snackbar";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState({
    error: false,
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState(false);
  const navigate = useNavigate();
  const forgotAPI = async (e) => {
    e.preventDefault();
    if (email === "") {
      setEmailError({
        error: true,
        message: "Email is required",
      });
      return;
    }
    setLoading(true);
    const res = await resetPassword(email);
    setLoading(false);
    if (res.Error) {
      setSnack(true);
      return;
    }
    navigate("confirm");
  };
  return (
    <>
      <ScrollRestoration />
      <CustomizedSnackbars
        severity={"error"}
        message="Your email doesn't seem to be on our database."
        open={snack}
        handleClose={() => setSnack(false)}
      />
      <Navbar />
      <div className=" pt-5 mh-100vh d-flex align-items-center justify-content-center">
        <Grid container alignItems={"center"} justifyContent="center">
          <Grid item xs={12}>
            <Container sx={{maxWidth:"600px !important"}}>
              <Typography variant="h1" className="text-center my-4">
                Forgot your password?
              </Typography>
              <Typography className=" text-center mb-4">
                To reset your password, enter the email address you use to login
                into your account.
              </Typography>
              <form onSubmit={forgotAPI} style={{margin:"0 auto"}}>
                <TextFeildCustom
                  error={emailError.error}
                  helperText={emailError.message}
                  fullWidth
                  label="Email"
                  value={email}
                  className="mb-4"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError({
                      error: false,
                      message: "",
                    });
                  }}
                />
                <CustomButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  loader={loading ? 1 : 0}
                >
                  Next
                </CustomButton>
              </form>
            </Container>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
