import { Container, Grid, Typography } from "@mui/material";
import { ScrollRestoration } from "react-router-dom";
import { RecoverPasswordIcon } from "../assets/Icons";
import Navbar from "../components/shared/Navbar";

export default function ForgotPassword2() {
  return (
    <>
      <ScrollRestoration />
      <Navbar />
      <div className=" pt-5 mh-100vh d-flex align-items-center justify-content-center">
        <Grid container alignItems={"center"} justifyContent="center">
          <Grid item xs={12}>
            <Container maxWidth={"sm"}>
              <div className="d-flex align-items-center justify-content-center">
                <RecoverPasswordIcon />
              </div>
              <Typography align="center" variant="h2" className="text-center mb-4">
                Password recovery in progress
              </Typography>
              <Typography variant="body1" className="text-center my-1">
                Check your email, you'll receive an password recovery link shorty.
              </Typography>
            </Container>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
