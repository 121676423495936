import ClearIcon from "@mui/icons-material/Clear";
import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { LogoIcon } from "../../assets/Icons";
import { useDispatch } from "react-redux";
import { resetStateAddress } from "../../store/address/storeAddress";
import { resetStateBottomBar } from "../../store/bottom-bar/storeBottomBar";
import { resetStateCreateAccount } from "../../store/create-account/storeCreateAccount";
import { resetStateStripeOrder } from "../../store/create-order/storeStripeOrder";
import { resetStateDate } from "../../store/date/storeDate";
import { resetStateStoreOrder } from "../../store/order/storeOrder";
import { resetStateStoreProducts } from "../../store/products/storeProducts";
import { resetStateSteps } from "../../store/steps/storeSteps";
import { resetStateStripePaymentCard } from "../../store/stripeCreatePayment/storeStripPaymentCreate";
import { resetStateSubscription } from "../../store/subscription/storeSubscription";
import { resetStateUpdateOrder } from "../../store/updateOrder/storeUpdateOrder";
import { resetAllValidations } from "../../store/createAccountValidate/storeValidateAccount";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetAllStates = () => {
    dispatch(resetStateAddress());
    dispatch(resetStateBottomBar());
    dispatch(resetStateCreateAccount());
    dispatch(resetStateStripeOrder());
    dispatch(resetStateDate());
    dispatch(resetStateStoreOrder());
    dispatch(resetStateStoreProducts());
    dispatch(resetStateSteps());
    dispatch(resetStateStripePaymentCard());
    dispatch(resetStateSubscription());
    dispatch(resetStateUpdateOrder());
    dispatch(resetAllValidations());
    navigate("/");
  };
  return (
    <div className="navbar-container">
      <Container maxWidth="lg">
        <div className="d-flex align-items-center justify-content-between navbar py-3 cursor-pointer">
          <Box onClick={() => window.location.replace("https://foamlaundry.com.au/")}>
            <LogoIcon />
          </Box>
          <ClearIcon onClick={() => resetAllStates()} />
        </div>
      </Container>
    </div>
  );
}
