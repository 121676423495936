import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  disable: false,
  loading: false,
};

const toggleContinueAction = (state, action) => {
  state.disable = action.payload ? action.payload : !state.disable;
};
const toggleLoadingAction = (state, _) => {
  state.loading = !state.loading;
};
const resetStateactions = (state, action) => {
  return initialState;
};

const bottomBarSlice = createSlice({
  name: "BottomBar",
  initialState: { ...initialState },
  reducers: {
    toggleContinue: toggleContinueAction,
    toggleLoading: toggleLoadingAction,
    resetStateBottomBar: resetStateactions,
  },
});

export const { toggleContinue, toggleLoading, resetStateBottomBar } =
  bottomBarSlice.actions;
export default bottomBarSlice.reducer;
