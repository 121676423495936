import * as React from "react";
import { Dialog, DialogContent, Slide, Box } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backgroundColor: "#BBDAD9DB",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    backgroundColor: "#333",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDailog({ open, handleClickClose, children }) {
  const classes = useStyles();
  return (
    <BootstrapDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClickClose}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogContent className=" p-0 mb-3">
        <Box sx={{ overflow: "hidden" }} className="bg-white">
          {children}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}
