import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  date: null,
  frequency: null,
  deliveryRange: null,
};

const setDateAction = (state, action) => {
  state.date = action.payload;
};
const setFrequencyAction = (state, action) => {
  state.frequency = action.payload;
};
const setDeliveryRangeAction = (state, action) => {
  state.deliveryRange = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};

export const dateSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    setDate: setDateAction,
    setFrequency: setFrequencyAction,
    setDeliveryRange: setDeliveryRangeAction,
    resetStateDate: resetStateactions,
  },
});

export const { setDate, setFrequency, setDeliveryRange, resetStateDate } =
  dateSlice.actions;
export default dateSlice.reducer;
