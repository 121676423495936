import { CircularProgress, Divider, List, ListItem } from "@mui/material";
import axios from "axios";

import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import StoreAddress from "./StoreAddress";
import TextFeildCustom from "./TextFeild";

export class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address,
      newAddress: null,
      error: false,
    };
  }

  getZip = async (lat, lng) => {
    this.setState({
      error: false,
    });
    try {
      const { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBbRXW9J84yOtTL4q4fwE7lWg-9LawFr2k`
      );

      let zipcodes = [
        2035, 2034, 2033, 2032, 2031, 2017, 2016, 2021, 2010, 2024, 2026, 2025,
        2029, 2022, 2028, 2023, 2027, 2011,
      ];

      data.results[0].address_components.forEach((item) => {
        if (item.types.includes("postal_code")) {
          if (zipcodes.includes(Number(item.long_name))) {
            this.setState({
              newAddress: {
                formatted_address: this.state.address,
                latlng: [lat, lng],
              },
            });
          } else {
            this.setState({
              error: true,
            });
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        this.getZip(latLng.lat, latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <>
        <PlacesAutocomplete
          searchOptions={{ componentRestrictions: { country: ["au"] } }}
          value={this.state.address}
          shouldFetchSuggestions={this.state.address.length > 4}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          api-key={process.env.REACT_APP_GOOGLE_MAPS_KEY}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div style={{ position: "relative" }}>
              <TextFeildCustom
                error={this.state.error}
                label="Enter your address"
                autoComplete="off"
                helperText={
                  this.state.error ? "Sorry! we are not availble here." : ""
                }
                {...getInputProps({
                  className: "location-search-input w-100",
                })}
              />
              <List
                className="m-0 p-0 shadow  bg-white"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  left: 0,
                  right: 0,
                  top: 58,
                }}
              >
                {loading && (
                  <ListItem className="d-flex justify-content-center">
                    <CircularProgress />
                  </ListItem>
                )}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? "suggestion-item--active "
                    : "suggestion-item bg-primary ";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#fafafa",
                        cursor: "pointer",
                        padding: 10,
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                        padding: 10,
                      };
                  return (
                    <ListItem
                      key={index + "item"}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                      <Divider className="my-2" />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          )}
        </PlacesAutocomplete>
        <StoreAddress newAddress={this.state.newAddress} />
      </>
    );
  }
}
