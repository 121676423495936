import axios from "axios";

export const resetPassword = async (email) => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/passwordCustomer",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        customerEmail: email,
      }
    );

    return response.data;
  } catch (e) {
    return e;
  }
};
