import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import MobileStepper from "@mui/material/MobileStepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Navbar from "../components/shared/Navbar";
import { Container } from "@mui/system";
import Location from "../components/order/location/Location";
import FoamPlan from "../components/order/foam-plan/FoamPlan";
import Dates from "../components/order/dates/Dates";
import Account from "../components/order/account/Account";
import Payment from "../components/order/payment/Payment";
import Review from "../components/order/review/Review";
import Load from "../components/order/load/Load";
import OrderBottomBar from "../components/shared/OrderBottomBar";
import { Typography } from "@mui/material";
import { OrderComplete } from "./OrderComplete";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { ScrollRestoration } from "react-router-dom";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 5,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#405c6e",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#405c6e",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    left: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 15,
  height: 15,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#ffbbae",
    border: "1px solid #405c6e",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#405c6e",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {/* {icons[String(props.icon)]} */}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

export default function CustomizedSteppers() {
  const { step } = useSelector((store) => store.step);
  const { products, basketWeight } = useSelector((store) => store.products);
  const { subscription } = useSelector((store) => store.subscription);
  const { date, deliveryRange } = useSelector((store) => store.date);
  const { subTotal } = useSelector((store) => store.order);
  const { updateOrderID } = useSelector((store) => store.updateOrder);

  const steps = updateOrderID
    ? ["Location", "Load", "Dates", "Review"]
    : localStorage.getItem("cid")
    ? ["Location", "Foam Plan", "Load", "Dates", "Payment", "Review"]
    : [
        "Location",
        "Account",
        "Foam Plan",
        "Load",
        "Dates",
        "Payment",
        "Review",
      ];

  const activeStepScreen = (activeStep) => {
    if (updateOrderID) {
      switch (activeStep) {
        case 0:
          return <Location />;
        case 1:
          return <Load />;
        case 2:
          return <Dates />;
        case 3:
          return <Review />;
        default:
          return <OrderComplete />;
      }
    } else {
      if (localStorage.getItem("cid")) {
        switch (activeStep) {
          case 0:
            return <Location />;
          case 1:
            return <FoamPlan />;
          case 2:
            return <Load />;
          case 3:
            return <Dates />;
          case 4:
            return <Payment />;
          case 5:
            return <Review />;
          default:
            return <OrderComplete />;
        }
      } else {
        switch (activeStep) {
          case 0:
            return <Location />;
          case 1:
            return <Account />;
          case 2:
            return <FoamPlan />;
          case 3:
            return <Load />;
          case 4:
            return <Dates />;
          case 5:
            return <Payment />;
          case 6:
            return <Review />;
          default:
            return <OrderComplete />;
        }
      }
    }
  };

  return (
    <>
      <ScrollRestoration />
      <Navbar />
      {step < steps.length ? (
        <div
          style={{ minHeight: "100vh" }}
          className="d-flex flex-column justify-content-between"
        >
          <Container maxWidth="lg" className="my-5 py-5 fm-main-container-mobile">
            <Container maxWidth="md" sx={{paddingRight:0, paddingLeft:0}}>
              <Stepper
                alternativeLabel
                activeStep={step}
                connector={<ColorlibConnector />}
                sx={{ margin: 0, width: "100%" }}
                className="d-none d-md-flex"
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <MobileStepper
                variant="progress"
                steps={steps.length}
                position="static"
                activeStep={step}
                className="d-md-none"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "transparent",
                }}
              />
            </Container>
            <div className="py-5 d-flex align-items-center justify-content-center fm-main-container-mobile">
              {activeStepScreen(step)}
            </div>
          </Container>
          <div style={{ height: 120 }}></div>
          <OrderBottomBar
            continueDisabled={step === steps.length - 1}
            steps={steps}
          >
            <div>
              {products.length ? (
                <div>
                  <Typography variant="caption">Your estimate</Typography>
                  <Typography>
                    <b>Order:</b> {basketWeight}{" "}
                    {basketWeight === "0kg"
                      ? products.length >= 1
                        ? "+ Extras"
                        : ""
                      : products.length > 1
                      ? "+ Extras"
                      : ""}
                  </Typography>
                </div>
              ) : (
                ""
              )}

              {date ? (
                <Typography>
                  <b>Pick-up:</b> {moment(date).format("ddd DD MMM YYYY")}
                </Typography>
              ) : (
                ""
              )}
            </div>{" "}
            {!subTotal ? (
              products.length ? (
                <Typography variant="h4">
                  $
                  {deliveryRange === "24hrs"
                    ? (
                        (subscription ? 2 : 4) +
                        products.reduce(
                          (partial, item) =>
                            parseFloat(partial) +
                            parseFloat(item.price) * item.quantity,
                          0
                        )
                      ).toFixed(2)
                    : products
                        .reduce((partial, item) => {
                          return (
                            parseFloat(partial) +
                            parseFloat(item.price) * item.quantity
                          );
                        }, 0)
                        .toFixed(2)}
                </Typography>
              ) : (
                ""
              )
            ) : (
              <Typography variant="h4">${subTotal.toFixed(2)}</Typography>
            )}
          </OrderBottomBar>
        </div>
      ) : (
        <Container maxWidth="sm" className="py-5 fm-main-container-mobile">
          <OrderComplete />
        </Container>
      )}
    </>
  );
}
