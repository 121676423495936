import { Box, Divider, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../shared/Button";
import HorizontalList from "../../shared/HorizontalScrollList";
import {
  setDate,
  setFrequency,
  setDeliveryRange,
} from "../../../store/date/storeDate";
import { useEffect } from "react";
import { ScrollRestoration } from "react-router-dom";

export default function Dates() {
  const { deliveryRange, frequency, date } = useSelector((store) => store.date);
  const { subscription } = useSelector((store) => store.subscription);
  const dispatch = useDispatch();

  const chooseDelivery = (range) => {
    dispatch(setDeliveryRange(range));
  };
  const chooseFrequency = (time) => {
    dispatch(setFrequency(time));
  };
  const chooseDate = (date) => {
    dispatch(setDate(date.toString()));
  };

  useEffect(() => {
    if (!date) {
      if (moment().format("ddd").toLocaleLowerCase() === "fri")
        chooseDate(moment().add(3, "days"));
      else if (moment().format("ddd").toLocaleLowerCase() === "sat")
        chooseDate(moment().add(2, "days"));
      else chooseDate(moment().add(1, "day"));
    }
    if (!frequency) chooseFrequency("weekly");
    if (!deliveryRange) chooseDelivery("48hrs");
  });

  return (
    <>
      <Container maxWidth={"md"} sx={{paddingRight:0, paddingLeft:0}}>
        <ScrollRestoration />
        <Grid container spacing={4} justifyContent="center">
          <Grid item>
            <Typography variant="h1" className="text-center mb-4">
              Collection date
            </Typography>
            <Typography variant="body1" className="text-center m-0">
              Schedule your pick-up. Delivery applies during business hours on weekdays only.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <HorizontalList>
              {new Array(31).fill("").map((item, i) =>
                moment()
                  .add(i + 1, "days")
                  .format("dddd")
                  .toString()
                  .toLocaleLowerCase() === "saturday" ||
                moment()
                  .add(i + 1, "days")
                  .format("dddd")
                  .toString()
                  .toLocaleLowerCase() === "sunday" ? (
                  <Box
                    sx={{ borderRadius: "16px" }}
                    className={`d-flex  flex-column align-items-center p-3 bg-white mx-1 ${
                      moment(date).format("DD/MM/YYYY").toString() ===
                      moment()
                        .add(i + 1, "days")
                        .format("DD/MM/YYYY")
                        .toString()
                        ? "bg-secondary"
                        : ""
                    }`}
                    key={moment().add(i + 1, "days")}
                  >
                    <div className=" d-flex  flex-column align-items-center">
                      <Typography
                        variant="overline"
                        sx={{ opacity: 0.3, letterSpacing: 0 }}
                      >
                        {moment()
                          .add(i + 1, "days")
                          .format("YYYY")}
                      </Typography>
                      <Typography variant="overline" sx={{ opacity: 0.3, letterSpacing: 0 }}>
                        {moment()
                          .add("days", i + 1)
                          .format("MMMM")}
                      </Typography>
                    </div>
                    <Divider
                      className="my-2 mb-2 w-100 mx-3"
                      sx={{ borderColor: " #bbb" }}
                    />
                    <div className="d-flex flex-column align-items-center">
                      <Typography
                        variant="overline"
                        sx={{ opacity: 0.3 }}
                      >
                        {moment()
                          .add(i + 1, "days")
                          .format("ddd")}
                      </Typography>
                      <Typography variant="h3" sx={{ opacity: 0.3 }}>
                        {moment()
                          .add(i + 1, "days")
                          .format("DD")}
                      </Typography>
                    </div>
                  </Box>
                ) : (
                  <Box
                    sx={{ borderRadius: "16px" }}
                    className={`d-flex cursor-pointer flex-column align-items-center p-3 bg-white mx-1 ${
                      moment(date).format("DD/MM/YYYY").toString() ===
                      moment()
                        .add(i + 1, "days")
                        .format("DD/MM/YYYY")
                        .toString()
                        ? "bg-secondary"
                        : ""
                    }`}
                    onClick={() =>
                      chooseDate(
                        moment()
                          .add(i + 1, "days")
                          .toString()
                      )
                    }
                    key={moment().add(i + 1, "days")}
                  >
                    <div className=" d-flex  flex-column align-items-center">
                      <Typography variant="overline" sx={{ letterSpacing: 0 }}>
                        {moment()
                          .add(i + 1, "days")
                          .format("YYYY")}
                      </Typography>
                      <Typography variant="overline" sx={{ letterSpacing: 0 }}>
                        {moment()
                          .add("days", i + 1)
                          .format("MMMM")}
                      </Typography>
                    </div>
                    <Divider
                      className="my-2 mb-2 w-100 mx-3"
                      sx={{ borderColor: " #bbb" }}
                    />
                    <div className="d-flex flex-column align-items-center">
                      <Typography variant="overline">
                        {moment()
                          .add(i + 1, "days")
                          .format("ddd")}
                      </Typography>
                      <Typography variant="h3">
                        {moment()
                          .add(i + 1, "days")
                          .format("DD")}
                      </Typography>
                    </div>
                  </Box>
                )
              )}
            </HorizontalList>
          </Grid>
          <Grid
            item
            xs={12}
            className={`d-flex flex-column align-items-center justify-content-center ${
              subscription ? "d-block" : "d-none"
            }`}
          >
            <Typography variant="h4" align="center" className="mb-2">Set Frequency</Typography>
            <div className="mt-2 d-flex flex-column d-md-flex flex-md-row justify-content-center w-100">
              <CustomButton
                variant={frequency === "weekly" ? "contained" : "outlined"}
                sx={{fontWeight: 500, fontSize: "16px"}}
                className="m-2"
                size="small"
                color={frequency === "weekly" ? "secondary" : "primary"}
                onClick={() => chooseFrequency("weekly")}
              >
                Weekly
              </CustomButton>
              <CustomButton
                variant={frequency === "fortnightly" ? "contained" : "outlined"}
                sx={{fontWeight: 500, fontSize: "16px"}}
                className="m-2"
                size="small"
                color={frequency === "fortnightly" ? "secondary" : "primary"}
                onClick={() => chooseFrequency("fortnightly")}
              >
                Fortnightly
              </CustomButton>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <Typography variant="h4" align="center" className="mb-2">Delivery Range</Typography>
            <div className="mt-2 d-flex flex-column d-md-flex flex-md-row justify-content-center w-100">
              <CustomButton
                variant={deliveryRange === "24hrs" ? "contained" : "outlined"}
                sx={{fontWeight: 500, fontSize: "16px"}}
                className="m-2"
                size="small"
                color={deliveryRange === "24hrs" ? "secondary" : "primary"}
                onClick={() => chooseDelivery("24hrs")}
              >
                Next day + {subscription ? "$2" : "$4"}
              </CustomButton>
              <CustomButton
                variant={deliveryRange === "48hrs" ? "contained" : "outlined"}
                sx={{fontWeight: 500, fontSize: "16px"}}
                className="m-2"
                size="small"
                color={deliveryRange === "48hrs" ? "secondary" : "primary"}
                onClick={() => chooseDelivery("48hrs")}
              >
                48 hours
              </CustomButton>
              <CustomButton
                variant={deliveryRange === "72hrs" ? "contained" : "outlined"}
                sx={{fontWeight: 500, fontSize: "16px"}}
                className="m-2"
                size="small"
                color={deliveryRange === "72hrs" ? "secondary" : "primary"}
                onClick={() => chooseDelivery("72hrs")}
              >
                72 hours
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
