import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "./firebase";

export const getCustomer = async (email) => {
  let customerObj = [];
  const collectionRef = collection(firestore, "foam-customers");
  const condition = query(collectionRef, where("email", "==", email));
  const customer = await getDocs(condition);
  customer.forEach((item, i) => {
    customerObj.push(item);
  });
  return customerObj;
};
