import {
  RoomOutlined,
  EventOutlined,
  EventAvailableOutlined,
} from "@mui/icons-material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import OrderDailog from "./components/Dailog";
import React, { useState } from "react";
import { useEffect } from "react";
import { getOrders } from "../../../cleancloud/getOrders";
import moment from "moment/moment";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changeOrderID } from "../../../store/updateOrder/storeUpdateOrder";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { changeAddress } from "../../../store/address/storeAddress";
import { changeSubscription } from "../../../store/subscription/storeSubscription";
import { deleteOrder } from "../../../cleancloud/deleteOrder";
import CustomizedSnackbars from "../../shared/Snackbar";
import { changeShowScheduleButton } from "../../../store/scheduleButton/scheduleButton";
import { checkSubscription } from "../../../cleancloud/checkSubscription";
import AlertDialogSlide from "./components/DeleteModal";
import { auth } from '../../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function MyOrders() {
  const [user] = useAuthState(auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openDelete, setOpenDelete] = React.useState(false);

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const [open, setOpen] = React.useState(false);

  const [subscribed, setSubscribed] = useState(false);
  const [orders, setOrders] = useState(null);
  const [upcoming, setUpcoming] = useState(null);
  const [modalOrder, setModalOrder] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(null);
  const [snack, setSnack] = useState({
    severity: "error",
    open: false,
    message: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickOpen = (order) => {
    setOpen(true);
    setModalOrder(order);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateOrder = (order) => {
    dispatch(changeOrderID(order.id));
    dispatch(
      changeAddress({ formatted_address: order.address?.split("//").join("") })
    );
    dispatch(changeSubscription(subscribed));
    navigate("/order");
  };

  const getData = async () => {
    checkSubscription()
      .then((res) => (res && res.length ? setSubscribed(true) : setSubscribed(false)))
      .catch((e) => console.log(e));
    const response = await getOrders();
    if (response.pastOrders.Error) return;
    setOrders(response.pastOrders.Orders);
    if (response.upcomingOrders.Error) return;
    setUpcoming(response.upcomingOrders.Orders);
    if (!response.upcomingOrders.Orders.length)
      dispatch(changeShowScheduleButton(true));
    if (response.upcomingOrders.Orders.length)
      dispatch(changeShowScheduleButton(false));
  };

  const deleteUpcoming = async (orderID) => {
    setDeleteLoading(true);
    try {
      const response = await deleteOrder(orderID);
      if (response.Error) {
        setDeleteLoading(false);
        setSnack({
          open: true,
          message: response.Error,
          severity: "error",
        });
        return;
      }
      setDeleteLoading(false);
      setUpcoming(upcoming.filter((item) => item.id !== orderID));
      dispatch(changeShowScheduleButton(true));
      handleCloseDelete();
      setSnack({
        open: true,
        message: "Order deleted successfully",
        severity: "success",
      });
    } catch (error) {}
  };

  const optionsEnabled = (date) => {
    const hours = moment.unix(date).diff(moment(), "hours");
    return hours > 6 ? true : false;
  };

  useEffect(() => {
    if (!localStorage.getItem("cid")) {
      navigate("/login");
    }
    if(!user) {
      localStorage.removeItem("cid");
      navigate("/login");
    }
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomizedSnackbars
        open={snack.open}
        severity={snack.severity}
        message={snack.message}
        handleClose={() => setSnack({ ...snack, open: false, message: "" })}
      />

      <ScrollRestoration />
      <OrderDailog
        open={open}
        handleClickClose={handleClose}
        order={modalOrder}
      />
      {orders ? (
        <Grid container spacing={3} justifyContent="center" className="mb-5">
          <Grid item xs={12}>
            <Typography variant="h4">My Orders</Typography>
          </Grid>
          {upcoming.length ? (
            upcoming.map((order, i) => (
              <Grid key={"upcoming-" + i} item xs={12}>
                <Box
                  sx={{ overflow: "hidden", borderRadius: 5 }}
                  className="bg-white"
                >
                  <AlertDialogSlide
                    open={openDelete}
                    handleClose={handleCloseDelete}
                    handleDelete={() => deleteUpcoming(order.id)}
                    deleteLoading={deleteLoading}
                  />
                  <div className="bg-dark p-3 px-4 d-flex align-items-center justify-content-between">
                    <Typography variant="h6" className="text-white">
                      Active Order #{order.id}
                    </Typography>
                    <Button
                      variant="contained"
                      color="white"
                      onClick={handleClick}
                      className="text-capitalize"
                      sx={{ borderRadius: 5 }}
                    >
                      Options
                    </Button>
                    <Menu
                      disabled={true}
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={handleCloseMenu}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",

                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: "50%",
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform:
                              "translateY(-50%) translateX(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                      }}
                    >
                      <MenuItem
                        onClick={() => updateOrder(order)}
                        disabled={!optionsEnabled(order.pickupDate)}
                      >
                        Modify Order
                      </MenuItem>
                      <MenuItem
                        disabled={!optionsEnabled(order.pickupDate)}
                        onClick={() => {
                          handleClickOpenDelete();
                          handleCloseMenu();
                        }}
                      >
                        Cancel Order
                      </MenuItem>
                    </Menu>
                  </div>
                  <div>
                    <List>
                      <ListItem className="d-flex align-items-center px-4">
                        <RoomOutlined fontSize="small" />
                        <Typography className="mx-2">
                          Pick-up from {order.address?.split("//").join(" ")}
                        </Typography>
                      </ListItem>
                      <ListItem className="d-flex align-items-center px-4">
                        <EventOutlined fontSize="small" />
                        <Typography className="mx-2">
                          Pick-up on{" "}
                          {moment.unix(order.pickupDate).format("DD MMM YYYY")}
                        </Typography>
                      </ListItem>
                      <ListItem className="d-flex align-items-center px-4">
                        <AccessTimeIcon fontSize="small" />
                        <Typography className="mx-2">
                          Pick-up between 8am and 12pm
                        </Typography>
                      </ListItem>
                      <ListItem className="d-flex align-items-center px-4">
                        <EventAvailableOutlined fontSize="small" />
                        <Typography className="mx-2">
                          Delivering on{" "}
                          {moment
                            .unix(order.deliveryDate)
                            .format("DD MMM YYYY")}
                        </Typography>
                      </ListItem>
                    </List>
                  </div>
                  <div className="p-3 px-4 bg-info">
                    <Typography variant="caption" className="text-uppercase">
                      Subscription
                    </Typography>
                    <Typography>Wash Dry and Fold</Typography>
                  </div>
                  <div>
                    <List>
                      {order.products
                        .filter(
                          (item) =>
                            item.section === "7" || item.section === "17"
                        )
                        .map((item, i) => (
                          <ListItem
                            key={"ele-" + i}
                            className=" px-4 d-flex align-items-center justify-content-between"
                          >
                            <Stack
                              direction={"row"}
                              className="d-flex justify-content-between w-100"
                            >
                              <Typography>{item.name}</Typography>
                              <Typography>${item.pricePerUnit}</Typography>
                            </Stack>
                          </ListItem>
                        ))}

                      <Divider
                        sx={{ border: "1px solid #bbb" }}
                        className="my-1"
                      />
                      {order.products.filter(
                        (item) => item.section !== "7" && item.section !== "17"
                      ).length ? (
                        <>
                          <ListItem>
                            <Typography variant="caption" className="mx-2">
                              Large Items
                            </Typography>
                          </ListItem>
                          {order.products
                            .filter(
                              (item) =>
                                item.section !== "7" && item.section !== "17"
                            )
                            .map((item, i) => (
                              <ListItem
                                key={"large-items" + i}
                                className=" px-4 d-flex align-items-center justify-content-between"
                              >
                                <Stack
                                  direction={"row"}
                                  className="d-flex justify-content-between w-100"
                                >
                                  <Typography>
                                    {Number(item.quantity)} x {item.name}
                                  </Typography>
                                  <Typography>${item.pricePerUnit}</Typography>
                                </Stack>
                              </ListItem>
                            ))}
                          <Divider
                            sx={{ border: "1px solid #bbb" }}
                            className="my-1"
                          />{" "}
                        </>
                      ) : (
                        ""
                      )}
                      <ListItem className=" px-4 d-flex flex-column align-items-start justify-content-between">
                        <Typography variant="caption">Other</Typography>
                        <Stack
                          direction={"row"}
                          className="d-flex justify-content-between align-items-center w-100"
                        >
                          <Typography>Pick-up & Delivery fee </Typography>
                          <Typography>
                            {order.deliveryFee === "0.00"
                              ? "Free"
                              : "$" + order.deliveryFee}
                          </Typography>
                        </Stack>
                      </ListItem>
                      <ListItem className=" px-4 d-flex align-items-center justify-content-between">
                        <Stack>
                          <Typography variant="caption"></Typography>
                          <Typography>Next day delivery </Typography>
                        </Stack>
                        <Typography>
                          {moment
                            .unix(order.deliveryDate)
                            .diff(moment.unix(order.pickupDate), "days") === 1
                            ? subscribed
                              ? "$2.00"
                              : "$4.00"
                            : "Free"}
                        </Typography>
                      </ListItem>

                      {Number(order.discount) ? (
                        <>
                          <Divider
                            sx={{ border: "1px solid #bbb" }}
                            className="my-1"
                          />
                          <ListItem className=" px-4 d-flex align-items-center justify-content-between">
                            <Stack>
                              <Typography variant="caption"></Typography>
                              <Typography className={"text-success"}>
                                Discount
                              </Typography>
                            </Stack>
                            <Typography className={"text-success"}>
                              ${order.discount}
                            </Typography>
                          </ListItem>
                        </>
                      ) : (
                        ""
                      )}
                    </List>
                    <div
                      className=" p-4  d-flex align-items-center justify-content-between m-0"
                      style={{ backgroundColor: "#BBDAD9" }}
                    >
                      <Typography>Total </Typography>

                      <Typography>${order.total}</Typography>
                    </div>
                  </div>
                </Box>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Box sx={{ borderRadius: 5, overflow: "hidden" }}>
                <div className="bg-dark  p-3">
                  <Typography className="text-white" variant="h6">
                    Upcoming orders
                  </Typography>
                </div>
                <div className="bg-white p-3">
                  <Typography>No upcoming orders</Typography>
                  <Button className="mt-4 mb-3" variant="contained" sx={{ borderRadius: 8 }} onClick={() => navigate("/order")}>Schedule Pick-Up Now</Button>
                </div>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box
              sx={{ borderRadius: 5, overflow: "hidden" }}
              className="bg-white"
            >
              <div className="bg-dark p-3  d-flex align-items-center justify-content-between">
                <Typography variant="h6" className="text-white">
                  Past orders
                </Typography>
              </div>

              <List>
                {orders.length ? (
                  orders.map((item, i) => (
                    <ListItem
                      key={"orders" + i}
                      className=" px-4 d-flex align-items-center justify-content-between"
                      onClick={() => handleClickOpen(item)}
                      sx={{ borderTop: i === 0 ? "none" : "1px solid #eee" }}
                    >
                      <u>
                        <Typography className="cursor-pointer">
                          {moment
                            .unix(Number(item.createdDate))
                            .format("DD MMM YYYY")}
                        </Typography>
                      </u>
                      <Typography>${item.total}</Typography>
                    </ListItem>
                  ))
                ) : (
                  <ListItem><Typography>No completed orders</Typography></ListItem>
                )}
              </List>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="grow" />
        </div>
      )}
    </>
  );
}
