import { Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, ScrollRestoration, useNavigate } from "react-router-dom";
// import { FacebookIcon, GoogleIcon } from "../assets/Icons";
import { signin } from "../cleancloud/signin";
import CustomButton from "../components/shared/Button";

import Navbar from "../components/shared/Navbar";
import CustomizedSnackbars from "../components/shared/Snackbar";
import TextFeildCustom from "../components/shared/TextFeild";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState({
    error: false,
    message: "",
  });
  const [passwordError, setPasswordError] = useState({
    error: false,
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    open: false,
    message: "Error !",
    severity: "error",
  });

  function handleErrorClose() {
    setError({ ...error, open: false });
  }

  const loginAPI = async (e) => {
    e.preventDefault();

    if (!email.length) {
      setEmailError({ error: true, message: "Email is required" });
      return;
    }
    if (!password.length) {
      setPasswordError({ error: true, message: "Password is required" });
      return;
    }
    setLoader(true);
    const res = await signin(email, password);
    setLoader(false);
    if (res.Success) {
      localStorage.setItem("cid", JSON.stringify(res.cid));
      navigate("/account/orders");
    } else if (res.Error) {
      setError({
        ...error,
        open: true,
        message: "Incorrect email or password",
      });
    } else {
      setError({
        ...error,
        open: true,
        message: "Oh, something went wrong!",
      });
    }
  };

  useEffect(() => {
    if (localStorage.getItem("cid")) {
      navigate("/account/orders");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <ScrollRestoration />
      <CustomizedSnackbars
        severity={error.severity}
        message={error.message}
        open={error.open}
        handleClose={handleErrorClose}
      />
      <Navbar />
      <div className=" pt-5 mh-100vh d-flex align-items-center justify-content-center">
        <Grid container alignItems={"center"} justifyContent="center">
          <Grid item xs={12}>
            <Container sx={{maxWidth:"600px !important"}}>
              <Typography variant="h1" className="text-center mb-4">
                Sign in
              </Typography>
              <Typography className=" text-center mb-2">
                I want to be a Foamie{" "}
                <Link to={"/order"} className="text-dark">
                  Sign up
                </Link>
              </Typography>
              <form className="py-2" onSubmit={loginAPI}>
                <TextFeildCustom
                  error={emailError.error}
                  helperText={emailError.message}
                  fullWidth
                  label="Email*"
                  className="my-2"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError({
                      error: false,
                      message: "",
                    });
                  }}
                />
                <TextFeildCustom
                  error={passwordError.error}
                  helperText={passwordError.message}
                  fullWidth
                  label="Password*"
                  type="password"
                  className="my-2"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError({
                      error: false,
                      message: "",
                    });
                  }}
                />
                <CustomButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  className="my-4"
                  loader={loader ? 1 : 0}
                  disabled={loader}
                >
                  Continue
                </CustomButton>
              </form>
              <Link
                to={"/forgot-password"}
                className="my-2 text-dark text-center"
              >
                <Typography>I forgot my password</Typography>
              </Link>
              {/* <div className="my-4 d-flex align-items-center justify-content-start">
                <hr className="flex-1 m-0" />
                <Typography className="mx-4 text-uppercase">Or</Typography>
                <hr className="flex-1 m-0" />
              </div>
              <CustomButton
                fullWidth
                variant="contained"
                color="white"
                className="my-2"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <FacebookIcon />
                  <span className="mx-2">Continue with facebook</span>
                </div>
              </CustomButton>
              <CustomButton
                fullWidth
                variant="contained"
                color="white"
                className="my-2"
              >
                <div className="d-flex align-items-center justify-content-center">
                  <GoogleIcon />
                  <span className="mx-2">Continue with google</span>
                </div>
              </CustomButton> */}
            </Container>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
