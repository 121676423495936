import { Grid, Typography } from "@mui/material";
import Navbar from "../components/shared/Navbar";
import Button from "../components/shared/Button";
import { Link, ScrollRestoration } from "react-router-dom";
import { LoginIcon, SignupIcon } from "../assets/Icons";
export default function Home() {
  return (
    <div>
      <Navbar />
      <ScrollRestoration />
      <Grid container>
        <Grid item xs={12} md={6}>
          <div className="mh-100vhm w-100 d-flex align-items-center justify-content-center pt-5 bg-white">
            <div className="d-flex flex-column align-items-center h-100  p-3">
              <SignupIcon />
              <Typography variant="h2" component="div" className="my-1 mt-3 mb-2">
                I'm a new Customer
              </Typography>
              <Typography variant="body1"  className="my-1 mb-4">
                I'm booking my first order or getting a quote.
              </Typography>
              <Link to={"/order"} className="text-decoration-none text-dark">
                <Button
                  variant={"contained"}
                  color="primary"
                  disableElevation
                  onClick={() => {
                    localStorage.clear();
                  }}
                >
                  Continue
                </Button>
              </Link>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="mh-100vhm w-100 d-flex align-items-center justify-content-center pt-5 ">
            <div className="d-flex flex-column align-items-center h-100  p-3">
              <LoginIcon />
              <Typography variant="h2" component="div" className="my-1  mt-3 mb-2">
                I'm a regular Foamie
              </Typography>
              <Typography variant="body1" className="my-1 mb-4">
                I'm a grown up.
              </Typography>
              <Link to={"login"} className="text-decoration-none text-dark">
                <Button variant={"contained"} color="white" disableElevation>Login</Button>
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
