import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stripeCreatePayment: false,
};

const stripPaymentCreateAction = (state, action) => {
  state.stripeCreatePayment = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};

const stripePaymentSlice = createSlice({
  name: "stripeOrder",
  initialState,
  reducers: {
    stripPaymentCreate: stripPaymentCreateAction,
    resetStateStripePaymentCard: resetStateactions,
  },
});

export const { stripPaymentCreate, resetStateStripePaymentCard } =
  stripePaymentSlice.actions;
export default stripePaymentSlice.reducer;
