import * as React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Divider,
  Stack,
} from "@mui/material";
import {
  RoomOutlined,
  EventOutlined,
  EventAvailableOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import { makeStyles, styled } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    backgroundColor: "#333",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDailog({ open, handleClickClose, order }) {
  const classes = useStyles();
  return (
    <BootstrapDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClickClose}
      aria-describedby="alert-dialog-slide-description"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      {order ? (
        <DialogContent className=" p-0">
          <Box sx={{ overflow: "hidden" }} className="bg-white">
            <div className="bg-dark p-3 px-4 d-flex align-items-center justify-content-between">
              <Typography variant="h6" className="text-white">
                Order #{order.id}
              </Typography>
              <IconButton
                color="secondary"
                size="small"
                className="d-flex align-items-center bg-white"
                onClick={handleClickClose}
              >
                <CloseOutlined />
              </IconButton>
            </div>
            <div>
              <List>
                <ListItem className="d-flex align-items-center px-4">
                  <RoomOutlined fontSize="small" />
                  <Typography className="mx-2">
                    Pick-up from {order.address?.split("//").join("")}
                  </Typography>
                </ListItem>
                <ListItem className="d-flex align-items-center px-4">
                  <EventOutlined fontSize="small" />
                  <Typography className="mx-2">
                    Pick-up on{" "}
                    {moment.unix(order.pickupDate).format("DD MMM YYYY")}
                  </Typography>
                </ListItem>
                <ListItem className="d-flex align-items-center px-4">
                  <EventAvailableOutlined fontSize="small" />
                  <Typography className="mx-2">
                    Delivering on{" "}
                    {moment.unix(order.deliveryDate).format("DD MMM YYYY")}
                  </Typography>
                </ListItem>
              </List>
            </div>
            <div className="p-3 px-4 bg-info">
              <Typography variant="caption" className="text-uppercase">
                Subscription
              </Typography>
              <Typography>Wash Dry and Fold</Typography>
            </div>
            <div>
              <List>
                {order.products
                  .filter(
                    (item) => item.section === "7" || item.section === "17"
                  )
                  .map((item, i) => (
                    <ListItem
                      key={"ele-" + i}
                      className=" px-4 d-flex align-items-center justify-content-between"
                    >
                      <Stack
                        direction={"row"}
                        className="d-flex justify-content-between w-100"
                      >
                        <Typography>{item.name}</Typography>
                        <Typography>${item.pricePerUnit}</Typography>
                      </Stack>
                    </ListItem>
                  ))}

                <Divider sx={{ border: "1px solid #bbb" }} className="my-1" />
                {order.products.filter(
                  (item) => item.section !== "7" && item.section !== "17"
                ).length ? (
                  <>
                    <ListItem>
                      <Typography variant="caption" className="mx-2">
                        Large Items
                      </Typography>
                    </ListItem>
                    {order.products
                      .filter(
                        (item) => item.section !== "7" && item.section !== "17"
                      )
                      .map((item, i) => (
                        <ListItem
                          key={"large-items" + i}
                          className=" px-4 d-flex align-items-center justify-content-between"
                        >
                          <Stack
                            direction={"row"}
                            className="d-flex justify-content-between w-100"
                          >
                            <Typography>
                              {Number(item.quantity)} x {item.name}
                            </Typography>
                            <Typography>${item.pricePerUnit}</Typography>
                          </Stack>
                        </ListItem>
                      ))}
                    <Divider
                      sx={{ border: "1px solid #bbb" }}
                      className="my-1"
                    />{" "}
                  </>
                ) : (
                  ""
                )}
                <ListItem className=" px-4 d-flex flex-column align-items-start justify-content-between">
                  <Typography variant="caption">Other</Typography>
                  <Stack
                    direction={"row"}
                    className="d-flex justify-content-between align-items-center w-100"
                  >
                    <Typography>Pick-up & Delivery fee </Typography>
                    <Typography>
                      {order.deliveryFee === "0.00"
                        ? "Free"
                        : "$" + order.deliveryFee}
                    </Typography>
                  </Stack>
                </ListItem>

                {Number(order.discount) ? (
                  <>
                    <Divider
                      sx={{ border: "1px solid #bbb" }}
                      className="my-1"
                    />
                    <ListItem className=" px-4 d-flex align-items-center justify-content-between">
                      <Stack>
                        <Typography variant="caption"></Typography>
                        <Typography className={"text-success"}>
                          Discount
                        </Typography>
                      </Stack>
                      <Typography className={"text-success"}>
                        ${order.discount}
                      </Typography>
                    </ListItem>
                  </>
                ) : (
                  ""
                )}
              </List>
              <div
                className=" p-4  d-flex align-items-center justify-content-between m-0"
                style={{ backgroundColor: "#BBDAD9" }}
              >
                <Typography>Total </Typography>

                <Typography>${order.total}</Typography>
              </div>
            </div>
          </Box>
        </DialogContent>
      ) : (
        <Typography>Nothing to show!</Typography>
      )}
    </BootstrapDialog>
  );
}
