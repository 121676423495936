import { configureStore } from "@reduxjs/toolkit";
import addressReducer from "./address/storeAddress";
import subscriptionReducer from "./subscription/storeSubscription";
import productsReducer from "./products/storeProducts";
import dateReducer from "./date/storeDate";
import stepReducer from "./steps/storeSteps";
import bottomBarReducer from "./bottom-bar/storeBottomBar";
import createAccountReducer from "./create-account/storeCreateAccount";
import orderReducer from "./order/storeOrder";
import stripeOrderReducer from "./create-order/storeStripeOrder";
import stripePaymentReducer from "./stripeCreatePayment/storeStripPaymentCreate";
import updateOrderReducer from "./updateOrder/storeUpdateOrder";
import validateAccountReducer from "./createAccountValidate/storeValidateAccount";
import scheduleButtonReducer from "./scheduleButton/scheduleButton";
import oneTimeReducer from "./oneTime/oneTimePayment";

export default configureStore({
  reducer: {
    address: addressReducer,
    subscription: subscriptionReducer,
    products: productsReducer,
    date: dateReducer,
    step: stepReducer,
    bottomBar: bottomBarReducer,
    createAccount: createAccountReducer,
    order: orderReducer,
    stripeOrder: stripeOrderReducer,
    stripeCreatePayment: stripePaymentReducer,
    updateOrder: updateOrderReducer,
    validateAccount: validateAccountReducer,
    scheduleButton: scheduleButtonReducer,
    oneTime: oneTimeReducer,
  },
});
