import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
  acceptTerms: "",
  cid: "",
};
const resetStateactions = (state, action) => {
  return initialState;
};

const createAccountSlice = createSlice({
  name: "createAccount",
  initialState: { ...initialState },
  reducers: {
    firstNameAction: (state, action) => {
      state.firstName = action.payload;
    },
    lastNameAction: (state, action) => {
      state.lastName = action.payload;
    },
    emailAction: (state, action) => {
      state.email = action.payload;
    },
    phoneAction: (state, action) => {
      state.phone = action.payload;
    },
    passwordAction: (state, action) => {
      state.password = action.payload;
    },
    confirmPasswordAction: (state, action) => {
      state.confirmPassword = action.payload;
    },
    acceptTermsAction: (state, action) => {
      state.acceptTerms = action.payload;
    },
    createdAction: (state, action) => {
      state.cid = action.payload;
    },
    resetStateCreateAccount: resetStateactions,
  },
});

export const {
  firstNameAction,
  lastNameAction,
  emailAction,
  phoneAction,
  passwordAction,
  confirmPasswordAction,
  acceptTermsAction,
  createdAction,
  resetStateCreateAccount,
} = createAccountSlice.actions;

export default createAccountSlice.reducer;
