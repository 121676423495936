import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Spinner } from "react-bootstrap";

const BootstrapButton = styled(Button)({
  borderRadius: 30,
  padding: "10px 20px",
  textTransform: "capitalize",
  //   background: "#fff",
});

export default function CustomButton(props) {
  return (
    <BootstrapButton {...props} disableElevation>
      {props.children}
      {props.loader ? (
        <Spinner
          animation="grow"
          size="sm"
          className={
            props.variant === "contained" && props.color !== "white"
              ? "text-white mx-1"
              : "text-dark mx-1"
          }
        />
      ) : null}
    </BootstrapButton>
  );
}
