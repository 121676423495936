import {
  ArrowBackIosNewOutlined,
  ArrowForwardIosOutlined,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

function HorizontalList({ children }) {
  return (
    <Box sx={{ "::-webkit-scrollbar": { display: "none" } }}>
      <ScrollMenu
        LeftArrow={LeftArrow}
        RightArrow={RightArrow}
        className="no-scrollbar"
      >
        {children}
      </ScrollMenu>
    </Box>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <div
      className="d-none d-md-flex align-items-center bg-white p-2 "
      style={{ borderRadius: "12px", marginRight: "5px" }}
    >
      <ArrowBackIosNewOutlined
        disabled={isFirstItemVisible}
        onClick={() => scrollPrev()}
      />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <div
      className="d-none d-md-flex align-items-center bg-white p-2"
      style={{ borderRadius: "12px", marginLeft: "5px" }}
    >
      <ArrowForwardIosOutlined
        disabled={isLastItemVisible}
        onClick={() => scrollNext()}
      />
    </div>
  );
}

export default HorizontalList;
