import {
  PaymentElement,
  AddressElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  toggleContinue,
  toggleLoading,
} from "../../../store/bottom-bar/storeBottomBar";
import { changeStep } from "../../../store/steps/storeSteps";
import { updatePaymentMethod } from "../../../store/create-order/storeStripeOrder";
import { stripPaymentCreate } from "../../../store/stripeCreatePayment/storeStripPaymentCreate";
import { changeOneTime } from "../../../store/oneTime/oneTimePayment";
import { useState } from "react";
import CustomizedSnackbars from "../../shared/Snackbar";
import axios from "axios";
import { getCustomerData } from "../../../firebase/getCustomerData";

export default function FormCustom({ clientSecret, billingDiffrent, cardsData}) {
  const { stripeCreatePayment } = useSelector(
    (store) => store.stripeCreatePayment
  );
  const { paymentMethodId } = useSelector((store) => store.stripeOrder);
  const [snack2, setSnack2] = useState({
    open: false,
    message: "",
  });
  const [prevCard, setPrevCard] = useState(null);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const doPaymentCreation = async () => {
    dispatch(stripPaymentCreate(false));
    dispatch(toggleContinue(true));
    dispatch(toggleLoading());
    try {
      if (paymentMethodId) {
        dispatch(changeStep("next"));
        return;
      } else {
        const response = await stripe.confirmSetup({
          elements,
          redirect: "if_required",
        });
        dispatch(changeOneTime(true));
        dispatch(toggleContinue(false));
        dispatch(toggleLoading());
        if (response.error) {
          setSnack2({
            open: true,
            message: "Method of payment was rejected, please try a different one.",
          });
          return;
        }

        // Cancel previous intent
        if (prevCard) {
          const cancelReponse = await axios.post(
            `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/cancelSetupIntent`,
            {
              intentID: prevCard,
            }
          );

          if (cancelReponse.data.error) {
            console.log(cancelReponse.data.error);
            setSnack2({
              open: true,
              message: "There was a problem in the payment process.",
            });
          }
        }

        dispatch(updatePaymentMethod(response.setupIntent.payment_method));

        // Update user with default card. Required for CleanCloud to charge customers later in the process
        const data = await getCustomerData();
        axios
          .post(
            `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/updateCustomersPayment`,
            {
              customer: data.stripeCustomer,
              paymentID: response.setupIntent.payment_method,
            }
          )
          .catch(function (error) {
            console.log(error);

            setSnack2({
              open: true,
              message: "There was an error trying to update your payment method.",
            });
            return;
          });


        dispatch(changeStep("next"));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (stripeCreatePayment) doPaymentCreation();
    // eslint-disable-next-line
  }, [stripeCreatePayment]);

  useEffect(() => {
    // Save previous card
    if(cardsData.length) {
      cardsData.map(async (item, i) => {
        setPrevCard(item.id);
      });
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <CustomizedSnackbars
        open={snack2.open}
        severity={"error"}
        message={snack2.message}
        handleClose={() => setSnack2({ ...snack2, open: false })}
      />
      {clientSecret ? (
        <>
          <PaymentElement />
          {billingDiffrent ? (
            <AddressElement
              options={{
                mode: "billing",
                autocomplete: {
                  mode: "google_maps_api",
                  apiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
                },
              }}
              className="mt-4"
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      )}
    </>
  );
}
