import axios from "axios";
import { auth } from '../firebase/firebase';
import { signInAnonymously } from "firebase/auth";
import { getCustomer } from "../firebase/getCustomer";
import { addCustomer } from "../firebase/addCustomer";
import { updateCustomerInformation } from "../cleancloud/updateCustomerInformation";
import { getCustomerInformation } from "../cleancloud/getCustomerInformation";

export const signin = async (customerEmail, customerPassword) => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/loginCustomer",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        customerEmail,
        customerPassword,
      }
    );
    if (response.data.Error) return response.data;

    return signInAnonymously(auth)
      .then(async () => {
        const fireBaseCustomer = await getCustomer(customerEmail);
        const theCustomer = await getCustomerInformation(response.data.cid);
        if (!fireBaseCustomer.length) {
          const customerName = theCustomer.Name?.split(" ")[0] + " " + theCustomer.Name?.split(" ")[1];

          //  Create a new stripe customer
          const stripe = await axios.post(
            `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/createStripeCustomer`,
            {
              customerEmail,
              customerName,
            }
          );

          if (stripe.data.error) return { data: { Error: "Stripe Server Issue" } };

          // Create a Firebase user
          const firebaseCustomerCreated = await addCustomer(
            customerEmail,
            stripe.data.id
          );
          if (!firebaseCustomerCreated)
            return { data: { Error: "Firebase server Issue" } };

          localStorage.setItem(
            "firebaseID",
            JSON.stringify(firebaseCustomerCreated)
          );

          // Update CleanCloud Stripe Token
          const updateCleanCloud = await updateCustomerInformation({stripe: stripe.data.id}, response.data.cid);
          if (updateCleanCloud.Error) return updateCleanCloud.Error;

        } else {
          if (!fireBaseCustomer[0].id) return { Error: "Server Error" };
          localStorage.setItem(
            "firebaseID",
            JSON.stringify(fireBaseCustomer[0].id)
          );
        }
        return response.data;
      }).catch((error) => {
        console.log("Error upgrading anonymous account", error);
        return error;
      });
  } catch (e) {
    return e;
  }
};