import axios from "axios";
import { updateCustomer } from "../firebase/updateCustomer";

export const updateCustomerInformation = async (userData, cid = null) => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/updateCustomer",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        customerID: cid ? cid : JSON.parse(localStorage.getItem("cid")),
        ...userData,
      }
    );
    // eslint-disable-next-line
    const _ = await updateCustomer({
      email: userData.customerEmail,
    });

    return response.data;
  } catch (e) {
    return e;
  }
};
