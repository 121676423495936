import { Box, Divider, Typography, IconButton } from "@mui/material";
import { Remove, AddOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../../store/products/storeProducts";

export default function CartItem({ item }) {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);

  return (
    <Box
      sx={{
        borderRadius: '32px',
        border: products.filter((ele) => ele.id === item.id).length
          ? "2px solid #375D70"
          : "2px solid white",
        overflow: "hidden",
      }}
      className="bg-white mb-2 h-100 d-flex flex-column justify-content-between"
    >
      <div>
        <Box className="d-flex flex-column align-items-center justify-content-center p-3">
          <img
            src={
              item.ic
                ? "https://s3.us-east-2.amazonaws.com/cleancloudapp/ic/b/" +
                  item.ic
                : "https://jointedrail.com/wp-content/uploads/2020/11/noimage-1.png"
            }
            alt="item"
            className="cart-img"
          />
        </Box>
        <Divider className="mb-1 w-100" sx={{ border: "1px dashed #BBDAD9" }} />
        <div className="p-3 pb-0">
          <Typography>{item.name}</Typography>
          <Typography>{item.size}</Typography>
        </div>
      </div>
      <div>
        <div className="p-3 pt-0">
          <Typography variant="h6" className="mt-2" style={{fontFamily: 'futura-pt, sans-serif'}}>${item.price}</Typography>
        </div>
        <Divider className="my-1" sx={{ border: "1px dashed #BBDAD9" }} />
        <div className="p-3 d-flex align-items-center justify-content-between">
          <IconButton
            size="small"
            disabled={!products.filter((ele) => ele.id === item.id).length}
            sx={{
              borderRadius: "50%",
              padding: 0.5,
            }}
            className={`${
              products.filter((ele) => ele.id === item.id).length
                ? "bg-dark text-white"
                : "bg-light text-dark"
            } `}
            onClick={() => dispatch(removeFromCart(item))}
          >
            <Remove sx={{ fill: "white" }} fontSize="20" />
          </IconButton>
          <Box
            sx={{
              borderRadius: "50%",
              backgroundColor: products.filter((ele) => ele.id === item.id).length
                ? "#FFBBAE"
                : "#E8F3F3",
              padding: 1,
              height: 28,
              width: 28,
            }}
            className="d-flex align-items-center justify-content-center"
          >
            {products.filter((ele) => ele.id === item.id).length
              ? products.filter((ele) => ele.id === item.id)[0].quantity
              : 0}
          </Box>
          <IconButton
            onClick={() => dispatch(addToCart(item))}
            size="small"
            sx={{
              borderRadius: "50%",
              padding: 0.5,
            }}
            className={"bg-dark text-white"}
          >
            <AddOutlined sx={{ fill: "white" }} fontSize="20" />
          </IconButton>
        </div>
      </div>
    </Box>
  );
}
