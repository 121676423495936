import { Divider } from "@mui/material";
import { Stack, Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../../store/steps/storeSteps";
import CustomButton from "./Button";
import { useState } from "react";
import CustomizedSnackbars from "./Snackbar";
import { createCustomer } from "../../cleancloud/createCustomerInformation";
import {
  toggleContinue,
  toggleLoading,
} from "../../store/bottom-bar/storeBottomBar";
import { createdAction } from "../../store/create-account/storeCreateAccount";
import { stripPaymentCreate } from "../../store/stripeCreatePayment/storeStripPaymentCreate";
import { createOrder } from "../../cleancloud/createOrder";
import moment from "moment";
import { updateOrder } from "../../cleancloud/updateOrder";
import { createRepeatPickup } from "../../cleancloud/createRepeatPickup";
import { useNavigate } from "react-router-dom";
import {
  cPasswordErrChange,
  emailErrChange,
  firstNameErrChange,
  lastNameErrChange,
  passwordErrChange,
  phoneNoErrChange,
} from "../../store/createAccountValidate/storeValidateAccount";
// import axios from "axios";

export default function OrderBottomBar({ steps, children }) {
  const navigate = useNavigate();
  const { products } = useSelector((store) => store.products);
  const { address } = useSelector((store) => store.address);
  const { subscription } = useSelector((store) => store.subscription);
  const { date, deliveryRange, frequency } = useSelector((store) => store.date);
  const { loading, disable } = useSelector((store) => store.bottomBar);
  const { step } = useSelector((store) => store.step);
  const { paymentMethodId } = useSelector((store) => store.stripeOrder);
  const { updateOrderID } = useSelector((store) => store.updateOrder);
  const { termsAndConditions, checkList, subTotal, promoDiscount, comments } =
    useSelector((store) => store.order);
  const {
    firstName,
    lastName,
    email,
    phone,
    password,
    confirmPassword,
    cid,
    acceptTerms,
  } = useSelector((store) => store.createAccount);

  const [openSnack2, setSnack2] = useState({
    open: false,
    message: "",
  });

  // const elements = useElements();

  const dispatch = useDispatch();

  const accountCreation = async () => {
    if (cid) {
      dispatch(changeStep("next"));
      return;
    }
    if (firstName.trim() === "") {
      dispatch(
        firstNameErrChange({
          err: true,
          message: "First name is required",
        })
      );
      return;
    }
    if (lastName.trim() === "") {
      dispatch(
        lastNameErrChange({
          err: true,
          message: "Last name is required",
        })
      );
      return;
    }
    if (email.trim() === "") {
      dispatch(
        emailErrChange({
          err: true,
          message: "Email is required",
        })
      );
      return;
    }
    if (phone.trim() === "") {
      dispatch(
        phoneNoErrChange({
          err: true,
          message: "Phone no is required",
        })
      );
      return;
    }
    if (password.trim() === "") {
      dispatch(
        passwordErrChange({
          err: true,
          message: "Password is required",
        })
      );
      return;
    }

    if (password !== confirmPassword) {
      dispatch(
        cPasswordErrChange({
          err: true,
          message: "Passwords do not match",
        })
      );
      return;
    }
    if (!acceptTerms) {
      setSnack2({
        message: "Please accept the privacy policy.",
        open: true,
      });
      return;
    }

    dispatch(toggleContinue(true));
    dispatch(toggleLoading());
    try {
      const res = await createCustomer({
        customerName: firstName + " " + lastName,
        customerTel: phone,
        customerEmail: email,
        customerPassword: password,
        marketingOptIn: acceptTerms,
        customerAddress: address.formatted_address
          ?.split("//")
          .map((item) => item.trim())
          .join(" ")
          .trim(),
        customerLat: address.latlng ? address.latlng[0] : "",
        customerLng: address.latlng ? address.latlng[1] : "",
      });

      dispatch(toggleContinue(false));
      dispatch(toggleLoading());

      if (res.Error) {
        setSnack2({
          message: res.Error,
          open: true,
        });
        return;
      }

      //Save Sign-up event to GTM
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "sign_up",
      });

      localStorage.setItem("firebaseID", JSON.stringify(res.firebaseID));
      dispatch(createdAction(res.CustomerID));
      dispatch(changeStep("next"));
    } catch (e) {
      setSnack2({
        message: e.message,
        open: true,
      });
    }
  };

  const data = () => {
    if (paymentMethodId) {
      dispatch(changeStep("next"));
    } else dispatch(stripPaymentCreate(true));
  };

  const checkProducts = () => {
    const check = products.filter(
      (item) =>
        item.section === "7" || item.section === "17" || item.section === "9"
    );

    if (subscription) {
      if (
        products.reduce((partial, item) => {
          return parseFloat(partial) + parseFloat(item.price) * item.quantity;
        }, 0) < 35.0
      ) {
        setSnack2({
          open: true,
          message: " Minimum order fee of $35.00 applies.",
          severity: "error",
        });
        return;
      }
    } else {
      if (
        products.reduce((partial, item) => {
          return parseFloat(partial) + parseFloat(item.price) * item.quantity;
        }, 0) < 35.0
      ) {
        setSnack2({
          open: true,
          message:
            " Minimum order fee of $35 applies. Does not include $10 delivery fee.",
          severity: "error",
        });
        return;
      }
    }

    if (check.length) dispatch(changeStep("next"));
    else
      setSnack2({
        open: true,
        message: "Your basket is empty.",
        severity: "error",
      });
  };

  const handleContinue = async () => {
    setSnack2({ open: false, message: "", severity: "error" });
    if (updateOrderID) {
      if (step === 0) {
        if (address.formatted_address.trim() === "") {
          setSnack2({
            open: true,
            message: "Please enter a valid address.",
          });
          return;
        }
        dispatch(changeStep("next"));
      } else if (step === 1) checkProducts();
      else if (step === 2) dispatch(changeStep("next"));
      else if (step === 3) dispatch(changeStep("next"));
    } else {
      if (!JSON.parse(localStorage.getItem("cid"))) {
        if (step === 0) {
          if (address.formatted_address.trim() === "") {
            setSnack2({
              open: true,
              message: "Please enter a valid address",
            });
            return;
          }
          dispatch(changeStep("next"));
        } else if (step === 1) accountCreation();
        else if (step === 2) dispatch(changeStep("next"));
        else if (step === 3) checkProducts();
        else if (step === 4) dispatch(changeStep("next"));
        else if (step === 5) data();
      } else {
        if (step === 0) {
          if (address.formatted_address.trim() === "") {
            setSnack2({
              open: true,
              message: "Please enter a valid address.",
            });
            return;
          }
          dispatch(changeStep("next"));
        } else if (step === 1) dispatch(changeStep("next"));
        else if (step === 2) checkProducts();
        else if (step === 3) dispatch(changeStep("next"));
        else if (step === 4) data();
      }
    }
  };

  const handleBack = () => {
    if (step === 0) {
      navigate(-1);
      return;
    }
    dispatch(changeStep("back"));
  };

  // const removeIntent = async () => {
  //   if (oneTime) {
  //     // eslint-disable-next-line
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/cancelSetupIntent`,
  //       {
  //         intentID: paymentMethodId,
  //       }
  //     );
  //   }
  // };

  const placeOrder = async () => {
    if (updateOrderID) {
      try {
        dispatch(toggleLoading());
        dispatch(toggleContinue(true));
        const response = await updateOrder({
          customerID: JSON.parse(localStorage.getItem("cid")),
          orderID: updateOrderID,
          finalTotal: subTotal,
          discount: promoDiscount,
          deliveryFee: subscription ? 0 : "10.00",
          products,
          pickupDate: moment(date).valueOf() / 1000,
          deliveryDate:
            deliveryRange === "24hrs"
              ? moment(date).add(1, "day").valueOf() / 1000
              : deliveryRange === "48hrs"
              ? moment(date).add(2, "day").valueOf() / 1000
              : moment(date).add(3, "day").valueOf() / 1000,
          orderNotes: comments,
        });
        dispatch(toggleLoading());
        dispatch(toggleContinue(false));

        if (response.Error) return;

        dispatch(changeStep("next"));
        return;
      } catch (e) {
        dispatch(toggleLoading());
        dispatch(toggleContinue(false));
        console.log(e);
      }
    } else {
      try {
          dispatch(toggleLoading());
          dispatch(toggleContinue(true));

          const response = await createOrder({
            customerID: localStorage.getItem("cid")
              ? JSON.parse(localStorage.getItem("cid"))
              : cid,
            finalTotal: subTotal,
            discount: promoDiscount,
            deliveryFee: subscription ? 0 : "10.00",
            products,
            status: 3,
            pickupStart: "8am",
            pickupDate: moment(date).valueOf() / 1000,
            pickupEnd: "12pm",
            delivery: 1,
            deliveryDate:
              deliveryRange === "24hrs"
                ? moment(date).add(1, "day").valueOf() / 1000
                : deliveryRange === "48hrs"
                ? moment(date).add(2, "day").valueOf() / 1000
                : moment(date).add(3, "day").valueOf() / 1000,
            deliveryStart: "8am",
            deliveryEnd: "12pm",
            orderNotes: comments,
            paymentType: 2,
            sendEmail: 1,
            paid: 0,
          });
          if (!subscription) {
            dispatch(toggleLoading());
            dispatch(toggleContinue(false));
          }

          if (response.Error) return;
          dispatch(changeStep("next"));
      } catch (e) {
        console.log(e);
      }
      if (subscription) {
        try {
          const response = await createRepeatPickup({
            customerID: localStorage.getItem("cid")
              ? JSON.parse(localStorage.getItem("cid"))
              : cid,
            pickupDate: moment(date).valueOf() / 1000,
            pickupStart: "8am",
            pickupEnd: "12pm",
            frequency: frequency === "weekly" ? 1 : 2,
            deliveryDays:
              deliveryRange === "24hrs" ? 1 : deliveryRange === "48hrs" ? 2 : 3,
            deliveryStart: "8am",
            deliveryEnd: "12pm",
          });
          if (response.Error) return;

          dispatch(toggleLoading());
          dispatch(toggleContinue(false));
          dispatch(changeStep("next"));
        } catch (e) {
          console.log(e);
        }
      }

      return;
    }
  };

  return (
    <>
      <CustomizedSnackbars
        open={openSnack2.open}
        severity={"error"}
        message={openSnack2.message}
        handleClose={() => setSnack2({ ...openSnack2, open: false })}
      />
      <div
        className="order-bottom-bar d-flex align-items-center"
        style={{
          minHeight: 100,
          position: "fixed",
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: 3,
        }}
      >
        <Container maxWidth={"md"} className="pt-1 mt-5 mb-4">
          {step > (updateOrderID ? 0 : 1) ? (
            <>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                {children}
              </Stack>
              {products.length || date ? (
                <Divider
                  className="my-4"
                  sx={{ border: "1px dashed #375D70" }}
                />
              ) : (
                ""
              )}
            </>
          ) : null}

          <Stack direction={"row"} justifyContent="space-between">
            {steps.length - 1 !== step ? (
              <>
                <CustomButton
                  variant="outlined"
                  sx={{ minWidth: 130 }}
                  onClick={handleBack}
                >
                  Back
                </CustomButton>
                <CustomButton
                  variant="contained"
                  sx={{ minWidth: 130 }}
                  onClick={handleContinue}
                  disabled={disable}
                  loader={loading ? 1 : 0}
                >
                  Continue
                </CustomButton>
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center w-100">
                <CustomButton
                  variant="contained"
                  color="secondary"
                  className="text-uppercase"
                  sx={{ fontWeight: 500 }}
                  onClick={placeOrder}
                  fullWidth
                  disabled={!checkList || !termsAndConditions || disable}
                  loader={loading ? 1 : 0}
                >
                  Place Your Order
                </CustomButton>
              </div>
            )}
          </Stack>
        </Container>
      </div>
    </>
  );
}
