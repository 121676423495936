import {
  Grid,
  Typography,
  List,
  ListItem,
  Button,
  Divider,
  Stack
} from "@mui/material";
import { Container } from "@mui/system";
import { RoomOutlined } from "@mui/icons-material";
import { LocationSearchInput } from "../../shared/GooglePlaces";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { getCustomerInformation } from "../../../cleancloud/getCustomerInformation";
import { changeAddress } from "../../../store/address/storeAddress";
import { Spinner } from "react-bootstrap";
import { ScrollRestoration } from "react-router-dom";

const addressList = [{}];

export default function Location() {
  const { address } = useSelector((state) => state.address);
  const [action, setAction] = useState(false);
  const [customerAddress, setCustomerAddress] = useState(null);
  const dispatch = useDispatch();

  const getAddresses = async () => {
    setAction(true);
    const response = await getCustomerInformation();
    if (response.Address !== "") {
      setCustomerAddress(
        response.Address?.split("//")
          .map((item) => item.trim())
          .join(" ")
      );
    }
    setAction(false);
  };

  useEffect(() => {
    if (localStorage.getItem("cid")) getAddresses();
  }, []);
  return (
    <Container maxWidth="md" sx={{paddingRight:0, paddingLeft:0}}>
      <ScrollRestoration />
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h1" align="center" className="mb-4">Where are we stopping by?</Typography>
        </Grid>
        <Grid item xs={12}>
          <LocationSearchInput
            address={address.formatted_address ? address.formatted_address : ""}
          />
        </Grid>
        <Grid item xs={12}></Grid>
        {localStorage.getItem("cid") ? (
          <Grid item xs={12}>
            <List
              dense={false}
              subheader={(
                <Typography variant="h3" component="div" className="py-3 px-4" style={{fontSize:18, borderBottom:"solid #E8F3F3 1px"}}>Your saved address</Typography>
              )}
              sx={{ borderRadius: 5 }}
              className="bg-white py-1"
            >
              <div>
                <Divider
                  className={`w-100 my-2 ${
                    addressList.length > 1 ? "d-block" : "d-none"
                  }`}
                  sx={{ border: "1px solid #bbb" }}
                />
              </div>
              {customerAddress ? (
                <div>
                  <ListItem
                    className="px-4 py-3"
                    secondaryAction={
                      address.formatted_address
                        ?.split("  ")
                        .map((ele) => ele.trim())
                        .join(" ") === customerAddress.trim() ? (
                        <Button
                          edge="end"
                          aria-label="select"
                          color="error"
                          onClick={() =>
                            dispatch(
                              changeAddress({
                                formatted_address: "",
                              })
                            )
                          }
                        >
                          <Typography
                            className="text-capitalize"
                            color={"error"}
                          >
                            Deselect
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          edge="end"
                          aria-label="select"
                          onClick={() =>
                            dispatch(
                              changeAddress({
                                formatted_address: customerAddress,
                              })
                            )
                          }
                        >
                          <Typography className="text-capitalize">
                            Select
                          </Typography>
                        </Button>
                      )
                    }
                  >
                    <Stack direction={"row"}>
                      <RoomOutlined />
                      <div className="mx-2">
                        <Typography
                          variant="body1"
                          className="d-md-none"
                          sx={{ maxWidth: "200px" }}
                        >
                          {customerAddress}
                        </Typography>
                        <Typography
                          variant="body1"
                          className="d-none d-md-block"
                          sx={{ maxWidth: "280px" }}
                        >
                          {customerAddress}
                        </Typography>
                      </div>
                    </Stack>
                  </ListItem>
                  <Divider
                    className={`w-100 my-2 ${
                      addressList.length > 1 ? "d-block" : "d-none"
                    }`}
                    sx={{ border: "1px solid #bbb" }}
                  />
                </div>
              ) : action ? (
                <div className="d-flex justify-content-center py-3">
                  <Spinner animation="grow" size="sm" />
                </div>
              ) : (
                <ListItem className="px-4">
                  <Typography>No addresses found</Typography>
                </ListItem>
              )}
            </List>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Container>
  );
}
