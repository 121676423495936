import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subTotal: 0,
  promoDiscount: 0,
  comments: "",
  termsAndConditions: false,
  checkList: false,
};

const changeSubTotalAction = (state, action) => {
  state.subTotal = action.payload;
};
const changePromoDiscountAction = (state, action) => {
  state.promoDiscount = action.payload;
};
const changeCommentsAction = (state, action) => {
  state.comments = action.payload;
};
const changeTermsAndConditionsAction = (state, action) => {
  state.termsAndConditions = action.payload;
};
const changeCheckListAction = (state, action) => {
  state.checkList = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    changeSubTotal: changeSubTotalAction,
    changePromoDiscount: changePromoDiscountAction,
    changeComments: changeCommentsAction,
    changeTermsAndConditions: changeTermsAndConditionsAction,
    changeCheckList: changeCheckListAction,
    resetStateStoreOrder: resetStateactions,
  },
});

export const {
  changePromoDiscount,
  changeSubTotal,
  changeComments,
  changeTermsAndConditions,
  changeCheckList,
  resetStateStoreOrder,
} = orderSlice.actions;
export default orderSlice.reducer;
