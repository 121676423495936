import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { List, ListItem, ListItemText, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
import MarkerIcon from "@mui/icons-material/RoomOutlined";
import PaymentIcon from "@mui/icons-material/PaymentsOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import TodayIcon from "@mui/icons-material/TodayOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToAppOutlined";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import { LogoIcon } from "../../assets/Icons";
import CustomButton from "../shared/Button";
import { useState, useEffect, useCallback } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetStateAddress } from "../../store/address/storeAddress";
import { resetStateBottomBar } from "../../store/bottom-bar/storeBottomBar";
import { resetStateCreateAccount } from "../../store/create-account/storeCreateAccount";
import { resetStateStripeOrder } from "../../store/create-order/storeStripeOrder";
import { resetStateDate } from "../../store/date/storeDate";
import { auth } from '../../firebase/firebase';
import { signOut } from "firebase/auth";
import {
  resetCart,
  resetStateStoreProducts,
} from "../../store/products/storeProducts";
import { resetStateSteps } from "../../store/steps/storeSteps";
import { resetStateStoreOrder } from "../../store/order/storeOrder";
import { resetStateStripePaymentCard } from "../../store/stripeCreatePayment/storeStripPaymentCreate";
import { resetStateSubscription } from "../../store/subscription/storeSubscription";
import { resetStateUpdateOrder } from "../../store/updateOrder/storeUpdateOrder";
import { resetAllValidations } from "../../store/createAccountValidate/storeValidateAccount";

export const DrawerList = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = useState(null);

  const checkURL = useCallback(() => {
    if (location.pathname === "/account") setActiveItem("my_account");
    else if (location.pathname === "/account/address")
      setActiveItem("my_address");
    else if (location.pathname === "/account/payment")
      setActiveItem("payment_method");
    else if (location.pathname === "/account/orders")
      setActiveItem("my_orders");
    else if (location.pathname === "/account/subscriptions")
      setActiveItem("my_subscriptions");
    else if (location.pathname === "/account/refer")
      setActiveItem("refer_a_friend");
  }, [location]);

  const logout = () => {
    signOut(auth);
    localStorage.clear();
    dispatch(resetStateAddress());
    dispatch(resetStateBottomBar());
    dispatch(resetStateCreateAccount());
    dispatch(resetStateStripeOrder());
    dispatch(resetStateDate());
    dispatch(resetCart());
    dispatch(resetStateSteps());
    dispatch(resetStateStoreOrder());
    dispatch(resetStateStoreProducts());
    dispatch(resetStateStripePaymentCard());
    dispatch(resetStateSubscription());
    dispatch(resetStateUpdateOrder());
    dispatch(resetAllValidations());
    window.location.href = "https://foamlaundry.com.au";
  };

  useEffect(() => {
    checkURL();
  }, [checkURL]);
  return (
    <>
      <List sx={{ width: 230 }}>
        <Link to={"/account/orders"} className="text-decoration-none text-dark">
          <ListItem
            className="p-2 px-3 mb-2 d-flex justify-content-start"
            sx={activeItem === "my_orders" ? { background: "#E8F3F3" } : null}
          >
            <FormatListBulletedIcon />
            <span className="mx-1"></span>
            <ListItemText
              primary="My Orders"
              className="ml-2 text-capitalize"
            />
          </ListItem>
        </Link>

        <Link
          to={"/account/address"}
          className="text-decoration-none text-dark"
        >
          <ListItem
            className="p-2 px-3 mb-2 d-flex justify-content-start"
            sx={activeItem === "my_address" ? { background: "#E8F3F3" } : null}
          >
            <MarkerIcon />
            <span className="mx-1"></span>
            <ListItemText
              primary="My Address"
              className="ml-2 text-capitalize"
            />
          </ListItem>
        </Link>
        <Link
          to={"/account/payment"}
          className="text-decoration-none text-dark"
        >
          <ListItem
            className="p-2 px-3 mb-2 d-flex justify-content-start"
            sx={
              activeItem === "payment_method" ? { background: "#E8F3F3" } : null
            }
          >
            <PaymentIcon />
            <span className="mx-1"></span>
            <ListItemText
              primary="Payment Method"
              className="ml-2 text-capitalize"
            />
          </ListItem>
        </Link>

        <Link
          to={"/account/subscriptions"}
          className="text-decoration-none text-dark"
        >
          <ListItem
            className="p-2 px-3 mb-2 d-flex justify-content-start"
            sx={
              activeItem === "my_subscriptions"
                ? { background: "#E8F3F3" }
                : null
            }
          >
            <TodayIcon />
            <span className="mx-1"></span>
            <ListItemText
              primary="My Subscriptions"
              className="ml-2 text-capitalize"
            />
          </ListItem>
        </Link>
        <Link to={"/account"} className="text-decoration-none text-dark">
          <ListItem
            className="p-2 px-3 mb-2 d-flex justify-content-start"
            sx={activeItem === "my_account" ? { background: "#E8F3F3" } : null}
          >
            <AccountCircleIcon />
            <span className="mx-1"></span>
            <ListItemText
              primary="My Account"
              className="ml-2 text-capitalize"
            />
          </ListItem>
        </Link>
        <ListItem
          className="p-2 px-3 mb-2 d-flex justify-content-start "
          sx={{ cursor: "pointer" }}
          onClick={logout}
        >
          <ExitToAppIcon />
          <span className="mx-1"></span>
          <ListItemText primary="Log out" className="ml-2 text-capitalize" />
        </ListItem>
      </List>
    </>
  );
};

export function Drawer(props) {
  const navigate = useNavigate();
  const { showScheduleButton } = useSelector((store) => store.scheduleButton);
  return (
    <>
      <SwipeableDrawer {...props} sx={{ backdropFilter: "blur(3px)" }}>
        <div
          className="d-flex flex-column justify-content-between h-100 mb-3"
          onClick={props.onClose}
        >
          <div>
            <ListItem className="mt-3 d-flex flex-column justify-content-start pb-3">
              <Link to={"/account"}>
                <LogoIcon className="my-5" />
              </Link>
              <hr className="w-100" />
            </ListItem>
            <DrawerList />
          </div>
          <List>
            <ListItem className="d-flex justify-content-center align-items-center">
              <PhoneIcon />
              <span className="mx-2"></span>
              <Typography>1500 12 12 12</Typography>
            </ListItem>
            {showScheduleButton ? (
              <ListItem className="d-flex justify-content-center">
                <CustomButton
                  variant={"contained"}
                  onClick={() => navigate("/order")}
                >
                  Schedule pick-up
                </CustomButton>
              </ListItem>
            ) : (
              ""
            )}
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
}
