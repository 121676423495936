import axios from "axios";

export const getOrders = async () => {
  try {
    const pastOrders = await axios.post(
      "https://cleancloudapp.com/api/getOrders",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        customerID: JSON.parse(localStorage.getItem("cid")),
        sendProductDetails: 1,
        status: 2,
      }
    );
    const upcomingOrders = await axios.post(
      "https://cleancloudapp.com/api/getOrders",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        customerID: JSON.parse(localStorage.getItem("cid")),
        sendProductDetails: 1,
        completed: 0,
      }
    );

    return { upcomingOrders: upcomingOrders.data, pastOrders: pastOrders.data };
  } catch (e) {
    return e;
  }
};
