import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  basketWeight: "0kg",
};

const addKGAction = (state, action) => {
  const temp = state.products.filter(
    (item) => item.section !== action.payload.section
  );
  if (action.payload.id) {
    state.products = [{ ...action.payload, quantity: 1 }, ...temp];
    state.basketWeight = action.payload.name?.split(" ")[0];
  } else {
    state.products = temp;
    state.basketWeight = "0kg";
  }
};

const resetCartAction = (state, action) => {
  state.products = [];
  state.basketWeight = "0kg";
};
const addToCartAction = (state, action) => {
  if (state.products.filter((item) => item.id === action.payload.id).length) {
    state.products = [
      ...state.products.map((item) =>
        item.id === action.payload.id
          ? { ...item, quantity: item.quantity + 1 }
          : item
      ),
    ];

    return;
  }
  state.products.push({ ...action.payload, quantity: 1 });
};
const removeFromCartAction = (state, action) => {
  const index = state.products.findIndex(
    (item) => item.id === action.payload.id
  );
  if (index !== -1 && state.products[index].quantity === 1) {
    state.products = [
      ...state.products.filter((item) => item.id !== action.payload.id),
    ];
    return;
  }
  if (state.products.filter((item) => item.id === action.payload.id).length) {
    state.products = [
      ...state.products.map((item) =>
        item.id === action.payload.id
          ? { ...item, quantity: item.quantity - 1 }
          : item
      ),
    ];
    return;
  }
  state.products.push({ ...action.payload, quantity: 0 });
};

const setProductsAction = (state, action) => {
  state.products = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    addToCart: addToCartAction,
    removeFromCart: removeFromCartAction,
    addKG: addKGAction,
    resetCart: resetCartAction,
    setProducts: setProductsAction,
    resetStateStoreProducts: resetStateactions,
  },
});

export const {
  resetCart,
  addToCart,
  removeFromCart,
  addKG,
  setProducts,
  resetStateStoreProducts,
} = productsSlice.actions;
export default productsSlice.reducer;
