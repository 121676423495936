import axios from "axios";

export const getCustomerInformation = async (cid = null) => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/getCustomer",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        customerID: cid ? JSON.parse(cid) : JSON.parse(localStorage.getItem("cid")),
      }
    );
    return response.data;
  } catch (e) {
    return e;
  }
};
