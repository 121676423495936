import axios from "axios";
import { addCustomer } from "../firebase/addCustomer";
import { auth } from '../firebase/firebase';
import { signInAnonymously } from "firebase/auth";
import { updateCustomerInformation } from "../cleancloud/updateCustomerInformation";

export const createCustomer = async (userData) => {
  try {
    //Create CleanCloud account
    const response = await axios.post(
      "https://cleancloudapp.com/api/addCustomer",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        ...userData,
      }
    );

    if (response.data.Error) return response.data;

    // Create Stripe account
    const customerEmail = userData.customerEmail;
    const customerName = userData.customerName;
    const stripe = await axios.post(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/createStripeCustomer`,
      {
        customerEmail,
        customerName,
      }
    );
    if (stripe.data.error) return { data: { Error: "Stripe Server Issue" } };

    // Update CleanCloud Stripe Token
    const updateCleanCloud = await updateCustomerInformation({stripe: stripe.data.id}, response.data.CustomerID);
    if (updateCleanCloud.Error) return updateCleanCloud.Error;

    return signInAnonymously(auth)
      .then(async () => {
        var firebaseCustomer = await addCustomer(
          userData.customerEmail,
          stripe.data.id
        );
        if (!firebaseCustomer) return { data: { Error: "Firebase Server Issue" } };
        return { ...response.data, firebaseID: firebaseCustomer };
      }).catch((error) => {
        console.log("Error upgrading anonymous account", error);
        return error;
      });
  } catch (e) {
    return { data: { Error: "Server Issue" } };
  }
};
