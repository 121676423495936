import {
  CheckCircle,
  RoomOutlined,
  EventOutlined,
  EventAvailableOutlined,
  CreditCardOutlined
} from "@mui/icons-material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  Container,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
} from "@mui/material";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import CustomButton from "../components/shared/Button";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment/moment";
import { resetStateAddress } from "../store/address/storeAddress";
import { resetStateBottomBar } from "../store/bottom-bar/storeBottomBar";
import { resetStateCreateAccount } from "../store/create-account/storeCreateAccount";
import { resetStateStripeOrder } from "../store/create-order/storeStripeOrder";
import { resetStateDate } from "../store/date/storeDate";
import { resetStateStoreOrder } from "../store/order/storeOrder";
import { resetStateStoreProducts } from "../store/products/storeProducts";
import { resetStateSteps } from "../store/steps/storeSteps";
import { resetStateStripePaymentCard } from "../store/stripeCreatePayment/storeStripPaymentCreate";
import { resetStateSubscription } from "../store/subscription/storeSubscription";
import { resetStateUpdateOrder } from "../store/updateOrder/storeUpdateOrder";
import { resetAllValidations } from "../store/createAccountValidate/storeValidateAccount";
import { resetOneTime } from "../store/oneTime/oneTimePayment";

export function OrderComplete() {
  const { address } = useSelector((store) => store.address);
  const { date, deliveryRange } = useSelector((store) => store.date);
  const { cid } = useSelector((store) => store.createAccount);
  const { subTotal } = useSelector((store) => store.order);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Save Purchase to GTM
  if (subTotal) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          transactionID: `FM_${Math.floor(Math.random() * 100001)}`,
          value: parseFloat(subTotal).toFixed(2),
          currency: "AUD",
        }
      },
    });
  }

  const resetAllStates = (toGo) => {
    dispatch(resetStateAddress());
    dispatch(resetStateBottomBar());
    dispatch(resetStateCreateAccount());
    dispatch(resetStateStripeOrder());
    dispatch(resetStateDate());
    dispatch(resetStateStoreOrder());
    dispatch(resetStateStoreProducts());
    dispatch(resetStateSteps());
    dispatch(resetStateStripePaymentCard());
    dispatch(resetStateSubscription());
    dispatch(resetStateUpdateOrder());
    dispatch(resetAllValidations());
    dispatch(resetOneTime());
    if (cid !== "") localStorage.setItem("cid", JSON.stringify(cid));
    if (toGo === "home") {
      navigate("/");
    } else {
      navigate("/account/orders");
    }
  };

  return (
    <>
      <ScrollRestoration />
      <Container style={{ maxWidth: 768 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid
            item
            className="d-flex flex-column align-items-center text-center"
          >
            <CheckCircle />
            <Typography variant="h1" className="py-4">
              Your order has been received
            </Typography>
            <Typography>Thanks. We'll process it right away!</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderRadius: 5, overflow: "hidden" }}>
              <div className="bg-dark  p-3 px-4">
                <Typography variant="h6" className="text-white">
                  Order information
                </Typography>
              </div>
              <div className="p-2  bg-white">
                <List>
                  <ListItem
                    className="d-flex align-items-center px-4"
                    key={"item" + 1}
                  >
                    <RoomOutlined fontSize="small" />
                    <Typography className="mx-1">
                      Pick-up from {address.formatted_address}
                    </Typography>
                  </ListItem>
                  <ListItem
                    className="d-flex align-items-center px-4"
                    key={"item" + 2}
                  >
                    <EventOutlined fontSize="small" />
                    <Typography className="mx-1">
                      Pick-up on {moment(date).format("dddd DD MMM YYYY")}
                    </Typography>
                  </ListItem>
                  <ListItem
                    className="d-flex align-items-center px-4"
                    key={"item" + 3}
                  >
                    <AccessTimeIcon fontSize="small" />
                    <Typography className="mx-1">
                        Pick-up between 8am and 12pm
                    </Typography>
                  </ListItem>
                  <ListItem
                    className="d-flex align-items-center px-4"
                    key={"item" + 4}
                  >
                    <EventAvailableOutlined fontSize="small" />
                    <Typography className="mx-1">
                      Delivering in {deliveryRange}
                    </Typography>
                  </ListItem>
                  <ListItem
                    className="d-flex align-items-center px-4"
                    key={"item" + 5}
                  >
                    <CreditCardOutlined fontSize="small" />
                    <Typography className="mx-1">
                        Your card will be charged after delivery
                    </Typography>
                  </ListItem>
                </List>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomButton
              variant="outlined"
              fullWidth
              onClick={() => resetAllStates("home")}
            >
              Back to homepage
            </CustomButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomButton
              variant="contained"
              fullWidth
              onClick={() => resetAllStates("account")}
            >
              My account
            </CustomButton>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
