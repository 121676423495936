import { Grid, Typography, List, ListItem, Button, Stack } from "@mui/material";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { useState } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "./CardForm";
import { loadStripe } from "@stripe/stripe-js";
import { getCustomerData } from "../../../firebase/getCustomerData";
import { ScrollRestoration, useNavigate } from "react-router-dom";
import { auth } from '../../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

export default function PaymentMethod() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [clientSecret, setClientSecret] = useState(null);
  // eslint-disable-next-line
  const [customerData, setCustomerData] = useState(null);
  const [cardsData, setCardsData] = useState([]);
  const [removeCardId, setRemoveCardId] = useState(null);
  const [reload, setReload] = useState(false);

  const getInformation = async () => {
    const data = await getCustomerData();
    axios
      .post(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/customerPaymentMethods`,
        {
          customer: data.stripeCustomer,
        }
      )
      .then(function (response) {
        setCardsData(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    setCustomerData(data);
    paymentMethod(data.stripeCustomer);
  };

  const paymentMethod = async (customer) => {
    axios
      .post(`${process.env.REACT_APP_FIREBASE_FUNCTIONS}/setupIntent`, {
        customer,
      })
      .then(function (response) {
        setClientSecret(response.data.client_secret);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteSetupIntent = async (id) => {
    setRemoveCardId(id);
    const response = await axios.post(
      `${process.env.REACT_APP_FIREBASE_FUNCTIONS}/cancelSetupIntent`,
      {
        intentID: id,
      }
    );

    if (response.data.success) {
      setCardsData(
        cardsData.filter((item) => item.id !== response.data.paymentMethod.id)
      );
    }
    setRemoveCardId(null);
  };

  useEffect(() => {
    if (!localStorage.getItem("cid")) {
      navigate("/login");
    }
    if(!user) {
      localStorage.removeItem("cid");
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getInformation();
    // eslint-disable-next-line
  }, [reload]);

  return (
    <>
      <ScrollRestoration />
      <Grid container spacing={3} justifyContent={"center"} className="mb-5">
        <Grid item xs={12}>
          <Typography variant="h4">Payment Method</Typography>
        </Grid>
        {cardsData ? (
          <Grid item xs={12}>
            <List
              dense={true}
              sx={{ borderRadius: 5 }}
              className="bg-white py-3 "
            >
              <ListItem className="px-4 mb-2">
                <Typography variant="h6">Cards</Typography>
              </ListItem>
              {cardsData.length ? (
                cardsData.map((item, i) => (
                  <ListItem
                    key={"card-" + i}
                    className="px-4 py-2"
                    sx={{
                      borderTop: i !== 0 ? "1px solid #eee" : "none",
                    }}
                    secondaryAction={
                      <>
                        <Button
                          edge="end"
                          aria-label="delete"
                          disabled={removeCardId === item?.id}
                          color="error"
                        >
                          {removeCardId === item.id ? (
                            <Spinner
                              size="sm"
                              className="mx-2"
                              animation="grow"
                            />
                          ) : (
                            <Typography
                              color={removeCardId !== item.id ? "error" : ""}
                              className="text-capitalize"
                              onClick={() => deleteSetupIntent(item.id)}
                            >
                              delete
                            </Typography>
                          )}
                        </Button>
                      </>
                    }
                  >
                    <Stack
                      direction={"row"}
                      className="d-flex align-items-center"
                    >
                      <CreditCardOutlinedIcon />
                      <div className="mx-2">
                        <Typography variant="body1" className="text-uppercase">
                          {item.card.brand}
                        </Typography>
                        <Typography variant="body2" className="m-0 p-0">
                          **** **** **** {item.card.last4}
                        </Typography>
                      </div>
                    </Stack>
                  </ListItem>
                ))
              ) : (
                <>
                  <ListItem className="px-4 mb-2"><Typography>Save a new payment method</Typography></ListItem>
                  <ListItem className="px-4 py-2">
                    <Grid container spacing={3} justifyContent={"right"}>
                      {clientSecret ? (
                        <Elements
                          stripe={stripePromise}
                          options={{
                            clientSecret,
                            appearance: {
                              theme: "stripe",
                              labels: "floating",
                              rules: {
                                ".Input": {
                                  border: "1px solid #999999",
                                },
                                ".Input:focus": {
                                  boxShadow: "rgba(64, 92, 110,0.2) 0 0 0 2px",
                                },
                              },
                              variables: {
                                colorPrimary: "#405c6e",
                                colorSuccess: "#39b555",
                                colorBackground: "#ffffff",
                                colorText: "#30313d",
                                colorDanger: "#E63D36",
                                fontFamily: "Ideal Sans, system-ui, sans-serif",
                                spacingUnit: "3px",
                                spacingGridRow: "25px",
                                // See all possible variables below
                              },
                            },
                          }}
                        >
                          <CardForm setReload={setReload} reload={reload} />
                        </Elements>
                      ) : (
                        <Spinner animation="grow" size="sm" />
                      )}
                    </Grid>
                  </ListItem>
                </>
              )}
            </List>
          </Grid>
        ) : (
          <div className="d-flex justify-content-center py-2">
            <Spinner animation="grow" size="sm" />
          </div>
        )}
      </Grid>
    </>
  );
}
