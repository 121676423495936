import axios from "axios";

export const createOrder = async (userData) => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/addOrder",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
        ...userData,
      }
    );

    return response.data;
  } catch (e) {
    return e;
  }
};
