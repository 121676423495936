import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CustomButton from "../../../shared/Button";
import { Spinner } from "react-bootstrap";
import { makeStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: "blur(5px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
}));

export default function AlertDialogSlide({
  open,
  handleClose,
  handleDelete,
  deleteLoading,
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="px-2">
          <DialogTitle>
            Are you sure you want to delete this order ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              If you delete this order you can not recover it.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={handleClose}>Cancel</CustomButton>
            <CustomButton
              color="error"
              variant="contained"
              onClick={() => handleDelete()}
              className="px-4"
              disabled={deleteLoading}
              size="small"
            >
              Delete Order
              {deleteLoading ? (
                <Spinner animation="grow" size="sm" className="mx-2" />
              ) : (
                ""
              )}
            </CustomButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
