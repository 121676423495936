import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Link, ScrollRestoration, useNavigate } from "react-router-dom";
import { getCustomerInformation } from "../../../cleancloud/getCustomerInformation";
import { updateCustomerInformation } from "../../../cleancloud/updateCustomerInformation";
import CustomButton from "../../shared/Button";
import CustomizedSnackbars from "../../shared/Snackbar";
import TextFeildCustom from "../../shared/TextFeild";
import CustomDailog from "../../shared/Dailog";
import { auth } from '../../../firebase/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

export default function MyAccount() {
  const [user] = useAuthState(auth);
  const [fnerr, setfnerr] = useState({
    err: false,
    message: "",
  });
  const [lnerr, setlnerr] = useState({
    err: false,
    message: "",
  });
  const [emerr, setemerr] = useState({
    err: false,
    message: "",
  });
  const [pnerr, setpnerr] = useState({
    err: false,
    message: "",
  });
  const [perr, setperr] = useState({
    err: false,
    message: "",
  });
  const [cperr, setcperr] = useState({
    err: false,
    message: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    open: false,
    message: "Error !",
    severity: "error",
  });
  const [privacyPolicyDailog, setPrivacyPolicyDailog] = useState(false);
  const navigate = useNavigate();

  const handleErrorClose = () => {
    setError({ ...error, open: false });
  };

  async function getCustomer() {
    const response = await getCustomerInformation();
    setFirstName(response.Name?.split(" ")[0]);
    setLastName(response.Name?.split(" ")[1]);
    setEmail(response.Email);
    setPhone(response.Tel);

    setAcceptTerms(response.marketingOptIn === "0" ? false : true);
  }

  async function updateCustomer() {
    if (firstName.trim() === "") {
      setfnerr({
        err: true,
        message: "First name is required",
      });
      return;
    }
    if (lastName.trim() === "") {
      setlnerr({
        err: true,
        message: "Last name is required",
      });
      return;
    }
    if (email.trim() === "") {
      setemerr({
        err: true,
        message: "Email is required",
      });
      return;
    }
    if (phone.trim() === "") {
      setpnerr({
        err: true,
        message: "Phone no is required",
      });
      return;
    }

    if (password) {
      if (password === confirmPassword) {
        setLoader(true);
        try {
          const res = await updateCustomerInformation({
            customerName: firstName + " " + lastName,
            customerTel: phone,
            customerEmail: email,
            customerPassword: password,
            marketingOptIn: acceptTerms,
          });
          if (res.Success) {
            setLoader(false);
            setError({
              ...error,
              open: true,
              severity: "success",
              message: "Information Updated! ",
            });
          } else {
            setError({
              open: true,
              severity: "error",
              message: res.Error,
            });
          }
        } catch (e) {
          setError({
            severity: "error",
            open: true,
            message: "Something went wrong! Try again",
          });
        }
      } else {
        setcperr({
          err: true,
          message: "Passwords do not match",
        });
        return;
      }
    } else {
      setLoader(true);
      try {
        const res = await updateCustomerInformation({
          customerName: firstName + " " + lastName,
          customerTel: phone,
          customerEmail: email,
          marketingOptIn: acceptTerms,
        });

        if (res.Success) {
          setError({
            open: true,
            severity: "success",
            message: "Information Updated ",
          });
        } else {
          setError({
            ...error,
            open: true,
            severity: "error",
            message: "Update failed ! Try again ",
          });
        }
      } catch (e) {
        setError({
          ...error,
          open: true,
          message: "Something went wrong! Try again",
        });
      }
    }
    setLoader(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("cid")) {
      navigate("/login");
    }
    if(!user) {
      localStorage.removeItem("cid");
      navigate("/login");
    }
    getCustomer();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ScrollRestoration />
      <CustomizedSnackbars
        severity={error.severity}
        message={error.message}
        open={error.open}
        handleClose={handleErrorClose}
      />
      <CustomDailog
        open={privacyPolicyDailog}
        handleClickClose={() => {
          setPrivacyPolicyDailog(false);
        }}
      >
        <div className="d-flex justify-content-between p-3 bg-dark">
          <Typography variant="h6" className="text-white">
            Privacy Terms
          </Typography>
          <IconButton
            color="secondary"
            size="small"
            className="d-flex align-items-center bg-white"
            onClick={() => {
              setPrivacyPolicyDailog(false);
            }}
          >
            <CloseOutlined />
          </IconButton>
        </div>
        <div className="p-3 py-2">
          <Typography variant="caption" sx={{ textAlign: "justify" }}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt
            magnam perferendis aut quibusdam, esse incidunt voluptatibus non
            odio nihil, omnis, et totam odit in sapiente iste amet eaque eveniet
            id modi saepe tempora natus provident praesentium. Non veniam
            officiis autem et vero sed, eos mollitia cum soluta? Fugit animi,
            expedita, quis eveniet sequi repellat iste perspiciatis, quam enim
            quia vitae totam. Nemo suscipit iusto repellat fugiat laborum ut non
            minima eveniet facilis. Modi nesciunt tenetur voluptatem quaerat
            optio, voluptas cupiditate necessitatibus ut officia sunt nobis
            distinctio ex sequi eius quam et quasi autem doloremque quia neque a
            iusto eligendi. Reiciendis alias quisquam laudantium ullam labore
            tempore repellendus animi accusamus doloribus obcaecati pariatur
            suscipit quos, repellat ut ipsum exercitationem aut itaque incidunt
            recusandae enim! Nostrum natus quam ratione neque. Aperiam
            recusandae ex aliquid dolorum eius, neque nulla delectus, animi
            officiis repellendus quia eum! Repellat, voluptas voluptate sit
            ipsam animi tenetur iste ratione at exercitationem accusantium
            assumenda enim accusamus sapiente amet id! Libero, nesciunt
            exercitationem. Tempora, ipsa. Odit cum ut dignissimos eos impedit
            vel commodi provident consequuntur molestias culpa saepe at, vero
            eius quasi error, doloremque quae. Officia laborum ullam voluptas
            itaque, maiores incidunt praesentium tempore laudantium blanditiis
            eius. Dicta molestias nobis fugit. Sequi minus pariatur iusto quae
            esse possimus voluptatum unde excepturi voluptatem architecto
            impedit totam eos laboriosam quis blanditiis optio, at sapiente
            beatae reprehenderit perferendis corporis sint nesciunt?
          </Typography>
        </div>
      </CustomDailog>
      <Grid container justifyContent={"center"} spacing={4} className="mb-5">
        <Grid item xs={12} className="mb-1 ">
          <Typography variant="h4">My Account</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            variant="filled"
            value={firstName}
            error={fnerr.err}
            helperText={fnerr.message}
            onChange={(e) => {
              setFirstName(e.target.value);
              setfnerr({
                err: false,
                message: "",
              });
            }}
            label="First Name*"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            variant="filled"
            value={lastName}
            error={lnerr.err}
            helperText={lnerr.message}
            onChange={(e) => {
              setLastName(e.target.value);
              setlnerr({
                err: false,
                message: "",
              });
            }}
            label="Last Name*"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            variant="filled"
            value={email}
            error={emerr.err}
            helperText={emerr.message}
            onChange={(e) => {
              setEmail(e.target.value);
              setemerr({
                err: false,
                message: "",
              });
            }}
            label="Email*"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            variant="filled"
            value={phone}
            error={pnerr.err}
            helperText={pnerr.message}
            onChange={(e) => {
              setPhone(e.target.value);
              setpnerr({
                err: false,
                message: "",
              });
            }}
            label="Phone*"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextFeildCustom
            type="password"
            variant="filled"
            value={password}
            error={perr.err}
            helperText={perr.message}
            onChange={(e) => {
              setPassword(e.target.value);
              setperr({
                err: false,
                message: "",
              });
            }}
            label="Change Password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFeildCustom
            type="password"
            variant="filled"
            value={confirmPassword}
            error={cperr.err}
            helperText={cperr.message}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setcperr({
                err: false,
                message: "",
              });
            }}
            label="Repeat Password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex align-items-center">
            <FormControlLabel
              className="d-flex align-items-start"
              control={
                <Checkbox
                  className="m-0 p-0 px-2"
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  I agree to have my order messages communicated by SMS and
                  receive service and marketing messages sent from FOAM Laundry,
                  knowing I can opt out anytime using my 'My Account'
                  preferences or simply texting 'STOP'. Phone numbers will be
                  kept confidential, consistent with our{" "}
                  <Link
                    onClick={() => setPrivacyPolicyDailog(true)}
                    className="text-dark"
                  >
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              }
            ></FormControlLabel>
          </div>
        </Grid>
        <Grid item>
          <CustomButton
            variant="contained"
            onClick={updateCustomer}
            loader={loader ? 1 : 0}
            disabled={loader}
          >
            Save Your Profile
          </CustomButton>
        </Grid>
      </Grid>
    </>
  );
}
