import { createBrowserRouter } from "react-router-dom";
import MyAccount from "../components/account/my-account/MyAccount";
import MyAddress from "../components/account/my-address/MyAddress";
import MyOrders from "../components/account/my-orders/MyOrders";
import MySubscriptions from "../components/account/my-subscriptions/MySubscriptions";
import PaymentMethod from "../components/account/payment-method/PaymentMethod";

import ForgotPassword from "../pages/ForgotPassword";
import ForgotPassword2 from "../pages/ForgotPassword2";
import Home from "../pages/Home";
import Login from "../pages/Login";
import CustomizedSteppers from "../pages/Order";
import UserAccount from "../pages/UserAccount";

const AppRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  { path: "/login", element: <Login /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/forgot-password/confirm", element: <ForgotPassword2 /> },
  {
    path: "/account",
    element: <UserAccount />,
    children: [
      { path: "", element: <MyAccount /> },
      { path: "address", element: <MyAddress /> },
      { path: "payment", element: <PaymentMethod /> },
      { path: "orders", element: <MyOrders /> },
      { path: "subscriptions", element: <MySubscriptions /> },
    ],
  },
  { path: "/order", element: <CustomizedSteppers /> },
]);

export default AppRoutes;
