import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  address: {
    formatted_address: "",
  },
};

const changeAddressAction = (state, action) => {
  state.address = action.payload;
};
const resetStateactions = (state, action) => {
  return initialState;
};

export const addressSlice = createSlice({
  name: "address",
  initialState: { ...initialState },
  reducers: {
    changeAddress: changeAddressAction,
    resetStateAddress: resetStateactions,
  },
});

export const { changeAddress, resetStateAddress } = addressSlice.actions;
export default addressSlice.reducer;
