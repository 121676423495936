import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showScheduleButton: false,
};

const scheduleButtonSlice = createSlice({
  name: "scheduleButton",
  initialState,
  reducers: {
    changeShowScheduleButton: (state, action) => {
      state.showScheduleButton = action.payload;
    },
  },
});

export const { changeShowScheduleButton } = scheduleButtonSlice.actions;
export default scheduleButtonSlice.reducer;
