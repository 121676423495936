import { createTheme } from "@mui/material/styles";

export let theme = createTheme();
theme = createTheme(theme, {
   palette: {
      primary: {
         light: "#548096",
         main: "#375D70",
         dark: "#223e4c",
      },
      white: {
         main: "#ffffff",
      },
      secondary: {
         light: "#FFD2CA",
         main: "#ffbbae",
         dark: "#ffa696",
         contrastText: '#375D70',
      },
      grey: {
         main: "#375D70",
      },
   },
   typography: {
      fontFamily: "futura-pt, sans-serif",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
      h1: {
         fontWeight: 700,
         letterSpacing: "-0.7px",
         fontSize: "32px",
         fontFamily: "new-spirit, sans-serif",
         lineHeight: "100%",
         [theme.breakpoints.up("md")]: {
            fontSize: "42px",
         },
      },
      h2: {
         fontWeight: 700,
         fontFamily: "new-spirit, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "32px",
         lineHeight: "calc(100% + 4px)",
         [theme.breakpoints.up("md")]: {
            fontSize: "38px",
         },
      },
      h3: {
         fontWeight: 700,
         fontFamily: "new-spirit, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "1.875rem",
         lineHeight: "calc(100% + 4px)",
      },
      h4: {
         fontWeight: 700,
         fontFamily: "new-spirit, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "1.625rem",
         lineHeight: "calc(100% + 4px)",
      },
      h5: {
         fontWeight: 700,
         fontFamily: "new-spirit, sans-serif",
         letterSpacing: "-0.5px",
         fontSize: "22px",
         lineHeight: "calc(100% + 4px)",
         [theme.breakpoints.up("sm")]: {
            fontSize: "24px",
         },
      },
      h6: {
         fontWeight: 700,
         fontFamily: "new-spirit, sans-serif",
         letterSpacing: "0",
         fontSize: "1rem",
         lineHeight: "calc(100% + 4px)",
         [theme.breakpoints.up("sm")]: {
            fontSize: "1.125rem",
         },
      },
      subtitle1: {
         fontWeight: 500,
         fontFamily: "futura-pt, sans-serif",
         textTransform: "initial",
         lineHeight: "calc(100% + 4px)",
         fontSize: "18px",
         [theme.breakpoints.down("md")]: {
            fontSize: "16px",
         },
      },
      subtitle2: {
         fontWeight: 500,
         fontFamily: "futura-pt, sans-serif",
         textTransform: "initial",
         lineHeight: "calc(100% + 4px)",
         fontSize: "16px",
         [theme.breakpoints.down("md")]: {
            fontSize: "14px",
         },
      },
      body1: {
        fontWeight: 400,
        fontFamily: "futura-pt, sans-serif",
        lineHeight: "calc(100% + 4px)",
        fontSize: "18px",
        "&.MuiTypography-gutterBottom": {
          marginBottom: "18px",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
      },
      body2: {
        fontWeight: 400,
        fontFamily: "futura-pt, sans-serif",
        fontSize: '16px',
        lineHeight: "calc(100% + 4px)",
        "&.MuiTypography-gutterBottom": {
          marginBottom: "0.8rem",
        },
      },
      button: {
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: '170%',
        letterSpacing: 0.5,
        fontFamily: "futura-pt, sans-serif",
        textTransform: "unset",
        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
      },
      caption: {
        fontSize: "14px",
        fontWeight: 500,
        opacity: 0.7,
        letterSpacing: "2px",
        fontFamily: "futura-pt, sans-serif",
      },
      overline: {
        fontFamily: "futura-pt, sans-serif",
        fontWeight: 400,
        lineHeight: 1.2,
        fontSize: "0.8125rem",
        letterSpacing: 2,
      },
   },
});
