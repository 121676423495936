import axios from "axios";

export const addCreditCard = async (paymentMethodID) => {
  try {
    const response = await axios.post("https://cleancloudapp.com/api/addCard", {
      api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
      customerID: JSON.parse(localStorage.getItem("cid")),
      type: 1,
      paymentMethodID,
    });

    return response.data;
  } catch (e) {
    return e;
  }
};
