import React, {useState, memo, useEffect} from "react";
import PropTypes from "prop-types";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { addKG } from "../../store/products/storeProducts";

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  borderRadius: 15,
  paddingRight: 100,
  padding: "13px 0",
  [theme.breakpoints.down("sm")]: {
    width: 'calc(100% - 48px)'
  },

  "& .MuiSlider-thumb": {
    height: 48,
    width: 80,
    [theme.breakpoints.down("sm")]: {
      height: 44,
      width: 56,
    },
    backgroundColor: "white",
    border: "3px solid currentColor",
    borderRadius: 32,
    zIndex: 3,
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
  },
  "& .MuiSlider-track": {
    height: 16,
    zIndex: 1,
    [theme.breakpoints.up("md")]: {
      height: 24,
    },
  },
  "& .MuiSlider-mark": {
    height: '12px',
    width: '12px',
    borderRadius: "50%",
    backgroundColor: "white",
    zIndex: 2,
    [theme.breakpoints.up("md")]: {
      height: '20px',
      width: '20px',
    },
  },
  "& .MuiSlider-mark[data-index='5']": {
    transform: 'translate(-110%, -50%)'
  },
  "& .MuiSlider-mark[data-index='0']": {
    transform: 'translate(10%, -50%)'
  },
  "& .MuiSlider-valueLabel": {
    top: "88%",
    backgroundColor: "transparent",
    fontSize: "16px",
    fontWeight: 500,
    [theme.breakpoints.up("md")]: {
      top: "85%",
      fontSize: "18px",
    },
  },
  "& .MuiSlider-rail": {
    color: theme.palette.mode === "dark" ? "#bfbfbf" : "#BBDAD9",
    opacity: theme.palette.mode === "dark" ? undefined : 0.5,
    height: 16,
    [theme.breakpoints.up("md")]: {
      height: 24,
    },
  },
}));

function valueLabelFormat(value) {
  switch(value) {
    case 1:
      value = 7;
      break;
    case 2:
      value = 9;
      break;
    case 3:
      value = 10;
      break;
    case 4:
      value = 12;
      break;
    case 5:
      value = 15;
      break;
    default:
      value = 0;
  }
  return value + "kg";
}

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
    </SliderThumb>
  );
}

AirbnbThumbComponent.propTypes = {
  children: PropTypes.node,
};

const CustomizedSlider = (props) => {
  const dispatch = useDispatch();
  const { basketWeight } = useSelector((store) => store.products);
  const [sliderValue, setSliderValue] = useState(0);
  useEffect(() => {
    console.log();
    if(basketWeight){
      switch(Number(basketWeight.slice(0, -2))) {
        case 7:
          setSliderValue(1);
          break;
        case 9:
          setSliderValue(2);
          break;
        case 10:
          setSliderValue(3);
          break;
        case 12:
          setSliderValue(4);
          break;
        case 15:
          setSliderValue(5);
          break;
        default:
          setSliderValue(0);
      }
    }
  },[basketWeight]);
  const changeMark = (_, e) => {
    const theIndex = () => {
      switch(e) {
        case 1:
          return 7;
        case 2:
          return 9;
        case 3:
          return 10;
        case 4:
          return 12;
        case 5:
          return 15;
        default:
          return 0;
      }
    }
    if (e) {
      const requiredData = props.sliderData.filter((item) =>
        item.name.includes(theIndex() + "kg")
      );
      dispatch(addKG(requiredData[0]));
    } else {
      const requiredData = props.sliderData.filter((item) =>
        item.name.includes(theIndex() + "kg")
      );
      dispatch(addKG({ ...requiredData[0], id: null }));
    }
  };
  const changeVal = (e, newVal) => {
    setSliderValue(newVal);
  };
  return (
    <>
      <AirbnbSlider
        aria-label="Custom marks"
        // slots={{ thumb: AirbnbThumbComponent }}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="on"
        step={null}
        value={sliderValue}
        key={`slider-${Number(basketWeight.slice(0, -2))}`}
        onChangeCommitted={changeMark}
        onChange={changeVal}
        marks={[{ label: "", value: 0 }, ...props.marks]}
        max={props.marks[props.marks.length - 1].value}
      />
    </>
  );
}
export default memo(CustomizedSlider);