import axios from "axios";
export const getProducts = async () => {
  try {
    const response = await axios.post(
      "https://cleancloudapp.com/api/getProducts",
      {
        api_token: process.env.REACT_APP_CLEAN_CLOUD_API_KEY,
      }
    );
    return response.data;
  } catch (e) {
    return e;
  }
};
