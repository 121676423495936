import { getDoc, doc } from "firebase/firestore";
import { firestore } from "./firebase";

export const getCustomerData = async (id) => {
  const docRef = doc(
    firestore,
    "foam-customers",
    JSON.parse(localStorage.getItem("firebaseID"))
  );

  const customer = await getDoc(docRef);
  return customer.data();
};
